import React, { Component, useEffect, useState } from "react";
import sectiondata from "../../data/sections.json";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import Api from "../../apis/apis";
import Select from "react-select";
import { convertNumber } from "../utils";

function SearchGrid() {
  let tempStates = [];
  const [properties, setProperties] = useState([]);
  let [states, setStates] = useState(null);

  let tempCities = [];
  let [cities, setCities] = useState({});

  let tempCategories = [];
  let [cate, setCate] = useState(null);

  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";
  let data = sectiondata.searchgrid;

  let [property, setProperty] = useState([]);
  let [count, setCount] = useState(null);

  let [city, setCity] = useState(null);

  const searchData = JSON.parse(sessionStorage.getItem("search") || "{}");

  let [selectSell, setSelectSell] = useState(searchData?.type == "sell");
  let [selectRent, setSelectRent] = useState(searchData?.type == "rent");

  let [typeF, setTypeF] = useState(searchData?.type || "");
  let [stateF, setStateF] = useState(searchData.state || null);
  let [cityF, setCityF] = useState(searchData.city || null);
  let [priceF, setPriceF] = useState(searchData.price || 0);
  let [propertyF, setPropertyF] = useState(searchData.property || null);

  useEffect(async () => {
    let res = await Api.allStates();
    if (res.status == "200") {
      for (let i = 0; i < res.data.length; i++) {
        tempStates.push({ value: res.data[i].id, label: res.data[i].name });
      }
      setStates(tempStates);
    }

    let res1 = await Api.allCities();
    if (res1.status == "200") {
      let cityObj = {};
      res1.data.forEach((item) => {
        let option = { value: item.id, label: item.name };
        cityObj[item.stateId] = cityObj[item.stateId]
          ? [...cityObj[item.stateId], option]
          : [option];
      });
      for (let i = 0; i < res1.data.length; i++) {
        tempCities.push({ value: res1.data[i].id, label: res1.data[i].name });
      }

      setCities(cityObj);
    }

    let data = {
      type: "sell",
    };

    let res2 = await Api.allCategoriesByType(data);
    if (res2.status == "200") {
      for (let i = 0; i < res2.data.length; i++) {
        tempCategories.push({
          value: res2.data[i].id,
          label: res2.data[i].name,
        });
      }
      setCate(tempCategories);
      // setTypeF("sell");
    }
  }, []);

  // useEffect(async () => {

  //   let res1 = await Api.allCategories();
  //   if (res1.status == '200') {
  //     setCity(res1.data);
  //   }

  //   if (sessionStorage.getItem('search')) {
  //     let data = JSON.parse(sessionStorage.getItem('search'))

  //     let res = await Api.searchPropertyHome(data);

  //     if (res.status == '200') {
  //       setProperty(res.data);
  //       setCount(res.count);
  //       console.log(res.data)
  //       sessionStorage.removeItem('search')
  //     }
  //     else {
  //       alert('No Record Found');
  //       sessionStorage.removeItem('search')
  //       let res = await Api.allProperties();
  //       if (res.status == '200') {
  //         setProperty(res.data);
  //         setCount(res.count);
  //       }
  //     }
  //   }
  //   else {
  //     let res = await Api.allProperties();
  //     if (res.status == '200') {
  //       setProperty(res.data);
  //       setCount(res.count);
  //     }
  //   }

  // }, [])

  const filterFunc = (item) => {
    let stateCondition = stateF?.value ? item.stateId == stateF.value : true;
    let cityCondition =
      cityF?.length > 0
        ? cityF.some((object) => object.value === Number(item.cityId))
        : true;
    let propertyCondition =
      propertyF?.length > 0
        ? propertyF.some((object) => object.value === Number(item.categoryId))
        : true;
    let priceCondition = priceF ? item.price <= +priceF : true;
    let type =
      typeF == "sell"
        ? item.type == "sell"
        : typeF == "rent"
        ? item.type == "rent"
        : item.type == "rent" || item.type == "sell";
    return (
      stateCondition &&
      cityCondition &&
      propertyCondition &&
      priceCondition & type
    );
  };

  useEffect(() => {
    const getAllProperties = async () => {
      let res = await Api.allProperties();
      if (res.status == "200") {
        setProperty(res.data);
        setCount(res.count);
        const propertyFiltered = res?.data?.filter((item) => filterFunc(item));
        setProperties(propertyFiltered || []);
      }
    };
    getAllProperties();
  }, []);

  const priceOption = [
    { value: 3000, label: "3000" },
    { value: 5000, label: "5000" },
    { value: 8000, label: "8000" },
    { value: 10000, label: "10000" },
    { value: 50000, label: "50000" },
    { value: 80000, label: "80000" },
    { value: 100000, label: "100000" },
    { value: 500000, label: "500000" },
    { value: 800000, label: "800000" },
    { value: 1000000, label: "1000000" },
    { value: 5000000, label: "5000000" },
    { value: 8000000, label: "8000000" },
    { value: 10000000, label: "10000000" },
    { value: 50000000, label: "50000000" },
    { value: 80000000, label: "80000000" },
    { value: 100000000, label: "100000000" },
  ];

  async function submitForm(e) {
    e.preventDefault();

    return;

    // if (typeF == null || stateF == null || cityF == null || priceF == null || propertyF == null) {
    //   alert('Select all fields to Search');
    //   return;
    // }

    let data = {
      type: typeF,
      state: stateF?.value || "",
      city: cityF?.value || "",
      price: priceF?.value || "",
      property: propertyF?.value || "",
    };

    let res = await Api.searchProperty(data);

    if (res.status == "200") {
      setProperty(res.data);
      setCount(res.count);
    } else {
      alert("No Record Found");
    }

    console.log(data);
    return;
  }

  // Set Value

  useEffect(() => {
    if (searchData) {
      setTypeF(searchData?.type);
      setStateF(searchData?.state);
      setCityF(searchData?.city);
      setPriceF(searchData?.price);
      setPropertyF(searchData?.property);
      const propertyFiltered = property.filter((item) => filterFunc(item));
      setProperties(propertyFiltered || []);
    }
  }, []);

  async function setTypeFunction(type) {
    let data = {
      type: type,
    };

    let res2 = await Api.allCategoriesByType(data);
    if (res2.status == "200") {
      for (let i = 0; i < res2.data.length; i++) {
        tempCategories.push({
          value: res2.data[i].id,
          label: res2.data[i].name,
        });
      }
      setCate(tempCategories);
      // setTypeF(type);
    }
  }

  const onFlter = () => {
    const propertyFiltered = property.filter((item) => filterFunc(item));
    setProperties(propertyFiltered || []);
  };

  const onReset = () => {
    setStateF("");
    setCityF([]);
    setPriceF(0);
    setPropertyF([]);
    sessionStorage.removeItem("search");
    setTypeF("");
    setProperties(property || []);
  };

  return (
    <>
      <div className="search-page-wrap pd-top-100 pd-bottom-70">
        <div className="search-container">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-4 sitebar">
                <h6 className="filter-title mb-4">
                  <img
                    className="mr-3"
                    src={publicUrl + "assets/img/icons/18.png"}
                    alt="img"
                  />
                  Filter
                </h6>
                <form
                  className="widget widget-sidebar-search-wrap"
                  onSubmit={submitForm}
                >
                  <div className="widget-sidebar-search">
                    <div className="title">Want To ?</div>
                    <div className="widget-sidebar-item-wrap btn-area">
                      <a
                        style={{
                          color: typeF === "sell" ? "white" : "#101425",
                        }}
                        className={typeF === "sell" ? "btn btn-yellow" : "btn"}
                        onClick={async () => {
                          setSelectSell(true);
                          setSelectRent(false);
                          setTypeF("sell");
                          await setTypeFunction("sell");
                        }}
                      >
                        For Buy
                      </a>
                      <a
                        style={{
                          color: typeF === "rent" ? "white" : "#101425",
                        }}
                        className={
                          typeF === "rent"
                            ? "btn btn-green-v1 float-right"
                            : "btn float-right"
                        }
                        onClick={async () => {
                          setSelectSell(false);
                          setSelectRent(true);
                          setTypeF("rent");
                          await setTypeFunction("rent");
                        }}
                      >
                        For Rent
                      </a>
                    </div>
                    {/*<div className="widget-sidebar-item-wrap rld-single-input left-icon">*/}
                    {/*  <input type="text" placeholder="Entry Landmark Location" />*/}
                    {/*</div>*/}
                    <div className="widget-sidebar-item-wrap rld-single-select">
                      <div className="title">State</div>
                      <Select
                        value={stateF}
                        onChange={setStateF}
                        options={states}
                      />
                    </div>
                    <div className="widget-sidebar-item-wrap rld-price-slider-wrap">
                      <div className="title">City</div>
                      <Select
                        isMulti
                        value={cityF}
                        onChange={setCityF}
                        options={stateF?.value ? cities[stateF.value] : []}
                      />
                    </div>
                    <div className="widget-sidebar-item-wrap rld-price-slider-wrap">
                      <div className="title">Property</div>
                      <Select
                        isMulti
                        onChange={setPropertyF}
                        value={propertyF}
                        options={cate}
                      />
                      <div className="container-fluid">
                        {/*<input type='range' min={5} max={200} required onChange={(e) => setAreaF(e.target.value)}/>*/}
                      </div>
                      {/*<i>Area: {areaF} sqf</i>*/}
                    </div>
                    <div className="widget-sidebar-item-wrap rld-single-select-wrap">
                      <div className="title">Price</div>
                      {/* Price slider */}
                      <div className="CustomPriceSlider">
                        <input
                          type="range"
                          min={3000}
                          max={100000000}
                          required
                          value={priceF}
                          onChange={(e) => setPriceF(e.target.value)}
                        />
                        {priceF !== 0 && priceF && (
                          <div>
                            <i>Price: {convertNumber(priceF)} INR</i>
                          </div>
                        )}
                      </div>
                      {/* End of Price Slider */}
                      {/* <Select
                        value={priceF}
                        onChange={setPriceF}
                        options={priceOption}
                      /> */}
                      <div className="rld-single-select d-inline-block float-right"></div>
                    </div>
                    <div className="widget-sidebar-item-wrap rld-single-select-wrap">
                      {/*<div className="title d-inline-block float-left mb-0 pt-2">Bathroom</div>*/}
                      <div className="rld-single-select d-inline-block float-right"></div>
                    </div>
                    <div className="widget-sidebar-item-wrap rld-single-select-wrap mb-0">
                      {/*<div className="title d-inline-block float-left mb-0 pt-2">Parking</div>*/}
                      <div className="rld-single-select d-inline-block float-right"></div>
                    </div>
                  </div>
                  <div style={{ clear: "both", display: "block" }}></div>
                  <div className="btn-wrap text-center">
                    <div style={{ color: "white" }}>
                      <span
                        className="left"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <button className="btn btn-yellow" onClick={onFlter}>
                          SEARCH
                        </button>
                        <button
                          onClick={() => {
                            onReset();
                          }}
                          className="btn btn-red"
                        >
                          Clear
                        </button>
                        <i />
                      </span>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-xl-9 col-lg-8">
                <div className="row mb-3">
                  <div className="col-md-9 col-sm-8">
                    <h6 className="filter-title mt-3 mb-lg-0">
                      {" "}
                      {properties?.length || 0} &nbsp;Properties
                    </h6>
                  </div>
                  {/*<div className="col-md-3 col-sm-4">*/}
                  {/*  <div className="rld-single-select">*/}
                  {/*    <select className="select single-select">*/}
                  {/*      <option value={1}>Tile View</option>*/}
                  {/*      <option value={2}>Tile View 1</option>*/}
                  {/*      <option value={3}>Tile View 2</option>*/}
                  {/*      <option value={3}>Tile View 3</option>*/}
                  {/*    </select>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
                <div className="row justify-content-center">
                  {properties?.map((item, i) => (
                    <div key={i} className="col-xl-4 col-sm-6">
                      <div className="single-feature">
                        <Link
                          onClick={() => {
                            sessionStorage.setItem("pid", item.id);
                            window.location.href = `/property-details/${item.id}`;
                          }}
                        >
                          <div className="thumb">
                            <img
                              style={{ height: "250px", width: "250px" }}
                              src={item.imageUrl1}
                              alt="img"
                            />
                          </div>
                          <div className="details">
                            {/*<a href="#" className="feature-logo">*/}
                            {/*    <img src={ publicUrl+item.icon } alt={ imagealt } />*/}
                            {/*</a>*/}
                            <p className="author">
                              <i className="fa fa-user" /> {item.ownerName}
                            </p>
                            <h6 className="title readeal-top">
                              <Link to={item.url}>{item.name}</Link>
                            </h6>
                            {item.type == "sell" ? (
                              <>
                                <h6 className="price">
                                  <b>Price</b> {item.price} INR
                                </h6>
                                <br />
                              </>
                            ) : (
                              <>
                                <h6 className="price">
                                  <b>Price</b> {item.price}/mo INR
                                </h6>
                                <br />
                              </>
                            )}
                            {/*<h6 className="price">Price { item.price } INR</h6><br/>*/}
                            <h6 className="price">
                              <b>For </b>
                              {item.type == "sell" ? "Sell" : "Rent"} (
                              {item.category})
                            </h6>
                            <h6 className="price">
                              <b>Furnished </b>
                              {item.furnished == "yes" ? "Yes" : "No"}
                            </h6>
                            <br />
                            <h6 className="price">
                              <b>City </b>
                              {item.city}
                            </h6>
                            <ul className="info-list">
                              {/*{ item.features.map( ( features, i )=>*/}
                              {/*    <li key={ i } ><i className={ features.icon } /> { features.title }</li>*/}
                              {/*) }*/}
                              <li>
                                <i className="fa fa-bed" /> {item.rooms}
                              </li>
                              <li>
                                <i className="fa fa-bath" /> {item.bathrooms}
                              </li>
                              <li>
                                <i className="fa fa-parking" /> {item.parking}
                              </li>
                              <li>
                                <i className="fa fa-square" /> {item.area} sq.
                              </li>
                            </ul>
                            <ul className="contact-list">
                              <li style={{ cursor: "pointer" }}>
                                <a
                                  className="phone"
                                  onClick={() => {
                                    sessionStorage.setItem("pid", item.id);
                                    window.location.href = "/property-details";
                                  }}
                                >
                                  <i className="fa fa-phone" />
                                </a>
                              </li>
                              <li style={{ cursor: "pointer" }}>
                                <a
                                  className="message"
                                  onClick={() => {
                                    sessionStorage.setItem("pid", item.id);
                                    window.location.href = "/property-details";
                                  }}
                                >
                                  <i className="fa fa-envelope" />
                                </a>
                              </li>
                              <li
                                style={{ cursor: "pointer" }}
                                className="readeal-top"
                              >
                                <div className="btn btn-yellow">
                                  View Details
                                </div>
                              </li>
                            </ul>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchGrid;
