import React,{useState,useEffect} from "react";
import './chat.css';
import user from '../assets/user.png'
import Api from "../apis/apis";


function Chat() {

    let [chat,setChat]=useState(null)
    let [code,setCode]=useState(null);

    let [chatUser,setChatUser]=useState(null);


    let [sender,setSender]= useState(sessionStorage.getItem('sender'));
    let [reciever,setReciever]= useState(sessionStorage.getItem('reciever'));
    let [message,setMessage]= useState(null);


    const getUsers = async ()=>{
        let data={
            senderID: sessionStorage.getItem('sender')
        }

        let res=await Api.getChatUser(data)
        if (res.status == '200')
        {
            setChatUser(res.data);
        }
    }


    useEffect( ()=>{

      getUsers()

    },[])


    async function sendMessage() {
        let data={
            senderID: sender,
            recieverId: reciever,
            type: 'send',
            status: 'unread',
            message: message
        }

        let res = await Api.sendMessageToDealer(data);
        if (res.status == '200')
        {
            setMessage('');
        }
        else
        {
            alert('Error Occured! Try Again');
        }
    }


    async function chatByCode(cd) {

        let data={
            code: cd,
        }

        let res = await Api.chatByCode(data)

        if (res.status == '200')
        {
            setChat(res.data);
            console.log(chat);
        }

    }
    
    
    async function refreshChat() {
        let data={
            code: code,
        }

        let res = await Api.chatByCode(data)

        if (res.status == '200')
        {
            setChat(res.data);
            console.log(chat);
        }
    }

    useEffect(async () => {
       await refreshAfterAMinute();
    }, []);

    async function refreshAfterAMinute() {
        if (code)
        {
            let data={
                code: code,
            }

            let res = await Api.chatByCode(data)

            if (res.status == '200')
            {
                setChat(res.data);
                console.log(chat);
            }
        }
        else
        {

        }
    }





    setInterval(refreshAfterAMinute, 30000);

    return(
        <>
            <div className="parent">
                <div className="child1">
                    {
                        chatUser?.map( (itm,i)=>(
                            <div className="container userInfo" onClick={async ()=>{setCode(itm.code);await chatByCode(itm.code)}}>
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-sm-4">
                                        <img src={user} className={'userImage'}/>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <p className={'userName'}>{itm.recieverName}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="child2">
                    <div className={'parentChat'}>
                        <div className={'childChat-1'}>
                            {
                                chat?.map((itm,i)=>(
                                    <p   className={itm.senderID == sessionStorage.getItem('sender')?'sendMsg':'recieveMsg'} >{itm.message}
                                    </p>
                                ))
                            }


                            {/*<p className={'recieveMsg'}>Lorem ipsum dolor sit amet, consectetur adipisicing elit.*/}
                            {/*    Accusamus accusantium aliquam aspernatur blanditiis consectetur debitis, deserunt*/}
                            {/*    dolorem, fuga ipsum minus modi, nihil odit provident qui repellat repellendus suscipit*/}
                            {/*    unde veniam.</p>*/}

                            {/*<p className={'recieveMsg'}>Lorem ipsum dolor sit amet, consectetur adipisicing elit.*/}
                            {/*    Accusamus accusantium aliquam aspernatur blanditiis consectetur debitis, deserunt*/}
                            {/*    dolorem, fuga ipsum minus modi, nihil odit provident qui repellat repellendus suscipit*/}
                            {/*    unde veniam.</p>*/}


                            {/*<p className={'sendMsg'}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus*/}
                            {/*    accusantium aliquam aspernatur blanditiis consectetur debitis, deserunt dolorem, fuga*/}
                            {/*    ipsum minus modi, nihil odit provident qui repellat repellendus suscipit unde*/}
                            {/*    veniam.</p>*/}
                            {/*<p className={'recieveMsg'}>Lorem ipsum dolor sit amet, consectetur adipisicing elit.*/}
                            {/*    Accusamus accusantium aliquam aspernatur blanditiis consectetur debitis, deserunt*/}
                            {/*    dolorem, fuga ipsum minus modi, nihil odit provident qui repellat repellendus suscipit*/}
                            {/*    unde veniam.</p>*/}
                            {/*<p className={'sendMsg'}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus*/}
                            {/*    accusantium aliquam aspernatur blanditiis consectetur debitis, deserunt dolorem, fuga*/}
                            {/*    ipsum minus modi, nihil odit provident qui repellat repellendus suscipit unde*/}
                            {/*    veniam.</p>*/}
                            {/*<p className={'recieveMsg'}>Lorem ipsum dolor sit amet, consectetur adipisicing elit.*/}
                            {/*    Accusamus accusantium aliquam aspernatur blanditiis consectetur debitis, deserunt*/}
                            {/*    dolorem, fuga ipsum minus modi, nihil odit provident qui repellat repellendus suscipit*/}
                            {/*    unde veniam.</p>*/}
                            {/*<p className={'sendMsg'}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus*/}
                            {/*    accusantium aliquam aspernatur blanditiis consectetur debitis, deserunt dolorem, fuga*/}
                            {/*    ipsum minus modi, nihil odit provident qui repellat repellendus suscipit unde*/}
                            {/*    veniam.</p>*/}
                        </div>
                        <div className={'childChat-2'}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                                        <p className="text-center">
                                            <button onClick={()=>{window.location.href='/property-details'}} className="fa fa-arrow-left chatButtons"></button>
                                        </p>
                                    </div>
                                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                                        <textarea className='form-control' rows={1} onChange={(e)=>setMessage(e.target.value)} value={message}></textarea>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                                        <p className="text-center">
                                            <button onClick={async ()=>{await sendMessage();await refreshChat()}} className="fa fa-telegram chatButtons"></button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )

}

export default Chat