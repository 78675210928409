import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import FaqSection from './section-components/faq';
import Footer from './global-components/footer';



function Contact() {


	return(
		<div>
			<Navbar />
			<PageHeader headertitle="Contact" />

			<Footer />
		</div>
	)

}



export default Contact

