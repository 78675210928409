import axios from "axios";

const env = process.env;

export const apiUrl = env.REACT_APP_API_URL || "https://backend.ungaveedu.com";

let live_url = `${apiUrl}/`;
let local_url = "http://localhost:4700/";

let api_url = live_url;
export let test_url = apiUrl;

let token = null;

const config = {
  headers: { "content-type": "multipart/form-data" },
};

async function checkLogin() {
  let authToken = sessionStorage.getItem("token");
  if (authToken) {
    token = { headers: { Authorization: `Bearer ${authToken}` } };
  } else {
    window.location.href = "/Registration";
    alert("You are not seem to be Login, Login First");
  }
}

export default class Api {
  // User SignIn and SignUp
  static userRegisterAndLogin = async (data) => {
    try {
      const res = await axios.post(api_url + `user-register-and-login`, data);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // Add Property
  static addProperty = async (data) => {
    try {
      const res = await axios.post(api_url + `add-property`, data);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // Save Property Images 1
  static savePropertyImage1 = async (data) => {
    try {
      const res = await axios.post(api_url + `save-property-image-1`, data);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };
  // Save Property Images 2
  static savePropertyImage2 = async (data) => {
    try {
      const res = await axios.post(api_url + `save-property-image-2`, data);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };
  // Save Property Images 3
  static savePropertyImage3 = async (data) => {
    try {
      const res = await axios.post(api_url + `save-property-image-3`, data);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };
  // Save Property Images 4
  static savePropertyImage4 = async (data) => {
    try {
      const res = await axios.post(api_url + `save-property-image-4`, data);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };
  // Save Property Images 5
  static savePropertyImage5 = async (data) => {
    try {
      const res = await axios.post(api_url + `save-property-image-5`, data);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // Save house address image
  static saveHouseAddressImage = async (data) => {
    try {
      const res = await axios.post(api_url + `save-house-address-image`, data);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // get all categories
  static allCategories = async () => {
    try {
      const res = await axios.get(api_url + `get-all-category`);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // get all categories
  static allCategoriesByType = async (data) => {
    try {
      const res = await axios.post(api_url + `get-all-category-by-type`, data);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // get all properties
  static allProperties = async () => {
    try {
      const res = await axios.get(api_url + `get-all-property`);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // property by user
  static propertyByUser = async (data) => {
    try {
      const res = await axios.post(api_url + `get-all-property-by-user`, data);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // delete property
  static deleteProperty = async (data) => {
    try {
      const res = await axios.post(api_url + `delete-property`, data);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // property by id
  static propertyById = async (data) => {
    try {
      const res = await axios.post(api_url + `property-by-id`, data);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // 4 properties for sold latest
  static propertiesForSellLatest = async () => {
    try {
      const res = await axios.get(api_url + `latest-property-for-sell`);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // latest 7 properties
  static latest7properties = async () => {
    try {
      const res = await axios.get(api_url + `latest-7-property`);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // latest 6 properties
  static latest6properties = async () => {
    try {
      const res = await axios.get(api_url + `latest-6-property`);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // search property
  static searchProperty = async (data) => {
    try {
      const res = await axios.post(api_url + `search-property`, data);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // search property
  static searchPropertyHome = async (data) => {
    try {
      const res = await axios.post(api_url + `search-property-from-home`, data);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // send message to dealer
  static sendMessageToDealer = async (data) => {
    try {
      const res = await axios.post(api_url + `send-message-to-dealer`, data);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // get chat users
  static getChatUser = async (data) => {
    try {
      const res = await axios.post(api_url + `get-chat-users`, data);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // get chat users
  static userById = async (data) => {
    try {
      const res = await axios.post(api_url + `user-by-id`, data);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // chat by code
  static chatByCode = async (data) => {
    try {
      const res = await axios.post(api_url + `get-chat-by-code`, data);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // check unread
  static checkUnread = async (data) => {
    try {
      const res = await axios.post(api_url + `check-unread-message`, data);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  // all state
  static allStates = async () => {
    try {
      const res = await axios.get(api_url + `all-state`);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };
  // all state
  static allCities = async () => {
    try {
      const res = await axios.get(api_url + `all-cities`);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };
  // cities by state
  static citiesByState = async (data) => {
    try {
      const res = await axios.post(api_url + `cities-by-state`, data);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };
  // all state
  static allTrends = async () => {
    try {
      const res = await axios.get(api_url + `all-trends`);
      return res.data;
    } catch (error) {
      return error.data;
    }
  };

  static getBestValueAction = async () => {
    try {
      const res = await axios.get(
        api_url + `ads/list/all?limit=3&type=Best value`
      );
      return res.data;
    } catch (error) {
      return error.data;
    }
  };
  static getHeaderAdsAction = async (data) => {
    try {
      const res = await axios.get(
        api_url + `ads/list/all?limit=1&type=${data.type}`
      );
      return res.data;
    } catch (error) {
      return error.data;
    }
  };
}
