import React from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import SearchGridSection from "./section-components/search-grid";
import Footer from "./global-components/footer";
import SearchAdd from "./section-components/SearchAdd";

const SearchGrid = () => {
  return (
    <div>
      <Navbar />
      {/* <PageHeader headertitle="Search Property for Rent and Sale" subheader="Search Properties" /> */}
      <SearchAdd type="Properties Header" />
      <SearchGridSection />
      <Footer />
    </div>
  );
};

export default SearchGrid;
