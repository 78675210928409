import React from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import Mission from "./section-components/mission";
import AboutUs from "./section-components/about-us";
import ServiceTwo from "./section-components/service-two";
import Team from "./section-components/team";
import Client from "./section-components/client";
import Footer from "./global-components/footer";

const About = () => {
  return (
    <div>
      <Navbar />
      <PageHeader headertitle="About" type="About Header" />
      {/* <Mission />
        <AboutUs /> */}
      <div className="about-area pd-bottom-30 pd-top-90">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p>
                {" "}
                Welcome to our India-based real estate portal, where we aim to
                simplify your property search and connect you with your dream
                home.
              </p>
              <p>
                Our mission is to provide an easy-to-use platform that helps you
                find the property of your dreams, anywhere in India. With our
                extensive listings of properties, we bring you the most
                comprehensive database of real estate to help you make informed
                decisions.
              </p>
              <p>
                Our team of experts works hard to ensure that our platform
                provides the latest and most up-to-date information on real
                estate in the country. We understand the importance of
                transparency in the real estate business, and we strive to
                provide you with accurate and reliable information about the
                properties listed on our website.
              </p>
              <p>
                With over a decade of experience in the real estate industry, we
                have built a reputation for providing quality service to our
                clients. We believe in putting the customer first, and our
                user-friendly platform is designed with your needs in mind.
              </p>
              <p>
                Whether you are looking to buy, sell or rent a property in
                India, our real estate portal provides you with everything you
                need to make informed decisions. Our advanced search options
                allow you to filter your results by location, price, and other
                important criteria, making your property search fast and easy.
              </p>
              <p>
                Thank you for choosing our real estate platform. We look forward
                to helping you find your dream home or property in India.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <ServiceTwo />
        <Team />
        <Client /> */}
      <Footer />
    </div>
  );
};

export default About;
