import React, { Component, useEffect, useState } from "react";
import sectiondata from "../../data/sections.json";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import Api from "../../apis/apis";

function Featured() {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";
  let data = sectiondata.featuredproperties;

  let [features, setFeatures] = useState(null);
  let [features6, setFeatures6] = useState(null);

  useEffect(async () => {
    let res = await Api.latest7properties();
    if (res.status == "200") {
      setFeatures(res.data);
    }

    let res1 = await Api.latest6properties();
    if (res1.status == "200") {
      setFeatures6(res1.data);
    }
  }, []);

  return (
    <>
      {features != null ? (
        <div className={"featured-area  pd-top-60"}>
          <div className="container">
            <div className="section-title text-center">
              <h2 className="title">{data.title}</h2>
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
                <div className="single-leading-feature">
                  <div className="slf-overlay" />
                  <div className="thumb">
                    <img
                      src={features ? features[0].imageUrl1 : ""}
                      alt={imagealt}
                    />
                  </div>
                  <div className="details">
                    <h4 className="title readeal-top">
                      <Link
                        onClick={() => {
                          sessionStorage.setItem(
                            "pid",
                            features?.features[0].id
                          );
                          window.location.href = `/property-details/${features?.features[0].id}`;
                        }}
                      >
                        {features ? features[0].name : ""}
                      </Link>
                    </h4>
                    {features ? (
                      features[0].type == "sell" ? (
                        <h5 className="price">
                          INR {features ? features[0].price : ""}
                        </h5>
                      ) : (
                        <h5 className="price">
                          INR {features ? features[0].price : ""} /mo
                        </h5>
                      )
                    ) : (
                      ""
                    )}
                    <span>
                      {features ? (
                        <>
                          <span>
                            {" "}
                            Rooms {features ? features[0].rooms : ""}
                          </span>
                          <span>
                            {" "}
                            Bathroom {features ? features[0].bathrooms : ""}
                          </span>
                          <span>
                            {" "}
                            Area {features ? features[0].area + " sq." : ""}
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
              </div>

              {features6?.map((item, i) => (
                <div key={i} className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                  <div className="single-feature">
                    <Link
                      onClick={() => {
                        sessionStorage.setItem("pid", item.id);
                        window.location.href = `/property-details/${item.id}`;
                      }}
                    >
                      <div className="thumb">
                        <img src={item.imageUrl1} alt={imagealt} />
                      </div>
                      <div className="details">
                        {/*<a href="#" className="feature-logo">*/}
                        {/*    <img src={publicUrl+item.icon} alt={ imagealt } />*/}
                        {/*</a>*/}
                        <p className="author">
                          <i className="fa fa-user" /> {item.ownerName}
                        </p>
                        <h6 className="title readeal-top">
                          <Link
                            onClick={() => {
                              sessionStorage.setItem("pid", item.id);
                              window.location.href = "/property-details";
                            }}
                          >
                            {item.name}
                          </Link>
                        </h6>
                        {item.type == "sell" ? (
                          <>
                            <h5 className="price">INR {item.price}</h5>
                            <br />
                          </>
                        ) : (
                          <>
                            <h5 className="price">INR {item.price} /mo</h5>
                            <br />
                          </>
                        )}
                        <h6 className="price">
                          <b>For </b>
                          {item.type == "sell" ? "Sell" : "Rent"} (
                          {item.category})
                        </h6>
                        <h6 className="price">
                          <b>Furnished </b>
                          {item.furnished == "yes" ? "Yes" : "No"}
                        </h6>
                        <ul className="info-list">
                          {/*{ item.features.map( ( features, i )=>*/}
                          {/*    <li key={ i } ><i className={ features.icon } /> { features.title }</li>*/}
                          {/*) }*/}
                          <li>
                            <i className="fa fa-bed" /> {item.rooms}
                          </li>
                          <li>
                            <i className="fa fa-bath" /> {item.bathrooms}
                          </li>
                          <li>
                            <i className="fa fa-parking" /> {item.parking}
                          </li>
                          <li>
                            <i className="fa fa-square" /> {item.area} sq.
                          </li>
                        </ul>
                        <ul className="contact-list">
                          <li style={{ cursor: "pointer" }}>
                            <a
                              className="phone"
                              onClick={() => {
                                sessionStorage.setItem("pid", item.id);
                                window.location.href = "/property-details";
                              }}
                            >
                              <i className="fa fa-phone" />
                            </a>
                          </li>
                          <li style={{ cursor: "pointer" }}>
                            <a
                              className="message"
                              onClick={() => {
                                sessionStorage.setItem("pid", item.id);
                                window.location.href = "/property-details";
                              }}
                            >
                              <i className="fa fa-envelope" />
                            </a>
                          </li>
                          <li
                            style={{ cursor: "pointer" }}
                            className="readeal-top"
                          >
                            <div className="btn btn-yellow">View Details</div>
                          </li>
                        </ul>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

// class Featured extends Component {
//
//
//     render() {
//
//         let publicUrl = process.env.PUBLIC_URL+'/'
//         let imagealt = 'image'
//         let data = sectiondata.featuredproperties
//         let Customclass = this.props.Customclass ? this.props.Customclass : 'pd-top-60'
//
//
//     return <div className={"featured-area  "+Customclass}>
//           <div className="container">
//             <div className="section-title text-center">
//               <h2 className="title">{ data.title }</h2>
//             </div>
//             <div className="row justify-content-center">
//               <div className="col-xl-6 col-lg-8">
//                 <div className="single-leading-feature">
//                   <div className="slf-overlay" />
//                   <div className="thumb">
//                     <img src={publicUrl+data.firstitem.image} alt={ imagealt } />
//                   </div>
//                   <div className="details">
//                     <h4 className="title readeal-top"><Link to={ data.firstitem.url  }>{ data.firstitem.title }</Link></h4>
//                     <h5 className="price">{ data.firstitem.price }</h5>
//                     <span>{ data.firstitem.content }</span>
//                   </div>
//                 </div>
//               </div>
//               { data.items.map( ( item, i )=>
//                   <div key={ i } className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
//                     <div className="single-feature">
//                       <div className="thumb">
//                         <img src={ publicUrl+item.image } alt={ imagealt } />
//                       </div>
//                       <div className="details">
//                         <a href="#" className="feature-logo">
//                           <img src={publicUrl+item.icon} alt={ imagealt } />
//                         </a>
//                         <p className="author"><i className="fa fa-user" /> { item.authorname }</p>
//                         <h6 className="title readeal-top"><Link to={ item.url }>{ item.title }</Link></h6>
//                         <h6 className="price">{ item.newerprice }</h6><del>{ item.olderprice }</del>
//                         <ul className="info-list">
//
//                         { item.features.map( ( features, i )=>
//                           <li key={ i } ><i className={ features.icon } /> { features.title }</li>
//                          ) }
//                           <li><img src={publicUrl+"/assets/img/icons/7.png"} alt="img" /> { item.area }</li>
//                         </ul>
//                         <ul className="contact-list">
//                           <li><a className="phone" href="#"><i className="fa fa-phone" /></a></li>
//                           <li><a className="message" href="#"><img src={ publicUrl+"/assets/img/icons/8.png" } alt="img" /></a></li>
//                           <li className="readeal-top"><Link className="btn btn-yellow" to={item.url}>View Details</Link></li>
//                         </ul>
//                       </div>
//                     </div>
//                   </div>
//                ) }
//
//             </div>
//           </div>
//         </div>
//
//
//
//         }
// }

export default Featured;
