import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';

class WhyChooseUs extends Component {


    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'
        let data = sectiondata.client
        let PaddingTop = this.props.PaddingTop ? this.props.PaddingTop : 'pd-top-90'
        let PaddingBottom = this.props.PaddingBottom ? this.props.PaddingBottom : 'pd-bottom-100'


    return <div className={"client-area "+PaddingTop+' '+PaddingBottom}>
          <div className="container">
            <div className="section-title text-center">
              <h2 className="title">What Our Customers <br /> Are Saying</h2>
            </div>
            <div className="client-review-img">
              <img className="clr-img clr-img1" src={publicUrl+"/assets/img/client/5.png"} alt="client" />
              <img className="clr-img clr-img2" src={publicUrl+"/assets/img/client/6.png"} alt="client" />
              <img className="clr-img clr-img3" src={publicUrl+"/assets/img/client/7.png"} alt="client" />
              <img className="clr-img clr-img4" src={publicUrl+"/assets/img/client/8.png"} alt="client" />
              <img className="clr-img clr-img5" src={publicUrl+"/assets/img/client/9.png"} alt="client" />
              <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-7 col-md-10">
                  <div className="client-slider-2 text-center">
                  { data.items.map( (item, i)=>
                      <div key={ i } className="item">
                        <div className="single-client-review">
                          <div className="thumb">
                            <img src={publicUrl+item.image} alt={ imagealt } />
                          </div>
                          <div className="review-details">
                            <p>{ item.content }</p>
                            <h4>{ item.name }</h4>
                            <p>{ item.designation }</p>
                          </div>
                        </div>
                    </div>
                   ) }

                  </div>
                </div>
              </div>
            </div>
          </div>
        <div className="contact-area pd-top-100 pd-bottom-65">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="contact-page-map">
                            <iframe className="w-100" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d60021.82409444856!2d-122.40118071595978!3d37.7546723469594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1577786376747!5m2!1sen!2sbd" style={{border: 0}} allowFullScreen />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <form className="contact-form-wrap contact-form-bg">
                            <h4>Contact Now</h4>
                            <div className="rld-single-input">
                                <input type="text" placeholder="Name" />
                            </div>
                            <div className="rld-single-input">
                                <input type="email" placeholder="Email" />
                            </div>
                            <div className="rld-single-input">
                                <input type="text" placeholder="Phone" />
                            </div>
                            <div className="rld-single-input">
                                <textarea rows={10} placeholder="Message" defaultValue={""} />
                            </div>
                            <div className="btn-wrap text-center">
                                <button className="btn btn-yellow">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="row pd-top-92">
                    <div className="col-xl-3 col-sm-6">
                        <div className="single-contact-info">
                            <p><i className="fa fa-phone" />Call Us:</p>
                            <h5>(000) 111 222 333</h5>
                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6">
                        <div className="single-contact-info">
                            <p><i className="fa fa-fax" />Fax:</p>
                            <h5>(000) 111 222 333</h5>
                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6">
                        <div className="single-contact-info">
                            <p><i className="fa fa-envelope" />Have any Question?</p>
                            <h5>example@codingeek.net</h5>
                        </div>
                    </div>
                    <div className="col-xl-3 col-sm-6">
                        <div className="single-contact-info">
                            <p><i className="fa fa-phone" />Address</p>
                            <h5>216 Trinity Ave, Pasadena,</h5>
                            <h5>CA 95046, United States</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>

        }
}

export default WhyChooseUs