import React, {Component, useEffect, useState} from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import Api from "../../apis/apis";


function Property() {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'
    let data = sectiondata.property

    let [property,setProperty]=useState(null);

    let [oneTime,setOneTime]=useState(true);



    useEffect(async ()=>{

        let data={
            id: JSON.parse(sessionStorage.getItem('auth'))?.id
        }

        let res = await Api.propertyByUser(data);

        if (res.status == '200')
        {
            
            setProperty(res.data)
        }
    },[])


    async function deleteProperty(id) {

        let data={
            id: id,
        }

        let res = await Api.deleteProperty(data);

        if (res.status == '200')
        {
            alert('Property Deleted');
            await refreshRecords();
        }
    }

    async function refreshRecords() {
        let data={
            id: JSON.parse(sessionStorage.getItem('auth'))?.id
        }

        let res = await Api.propertyByUser(data);

        if (res.status == '200')
        {
            setProperty(res.data)
        }
    }


    return(
        <>
            <div className="property-area pd-top-100">
                <div className="container">
                    <div class='row'>

                            { property?.map( ( item, i )=>
                                <div className="col-lg-3 col-md-3 col-sm-4">
                                <div key={ i } className={""}>
                                    <div className="single-feature">
                                        <div className="thumb">
                                            <img src={ item.imageUrl1 } style={{width: '100%'}} alt="img" />
                                        </div>
                                        <div className="details">
                                            {/*<a href="#" className="feature-logo">*/}
                                            {/*    <img src={ publicUrl+item.icon } alt={ imagealt } />*/}
                                            {/*</a>*/}
                                            <p className="author"><i className="fa fa-user" /> { item.ownerName }</p>
                                            <h6 className="title readeal-top"><Link to={ item.url }>{ item.name }</Link></h6>
                                            <h6 className="price">{ item.price } INR</h6><br/>
                                            <h6 className="price"><b>For  </b>{ (item.type == 'sell')?'Sell':'Rent' } ({item.category})</h6><br/>
                                            <h6 className="price"><b>Furnished </b>{ (item.furnished == 'yes')?'Yes':'No' }</h6><br/>
                                            <ul className="contact-list">
                                                <li><a className="phone" onClick={()=>{deleteProperty(item.id)}}><i style={{color: 'red'}} className="fa fa-trash" /></a></li>
                                                <li className="readeal-top"><Link className="btn btn-yellow" onClick={()=>{sessionStorage.setItem('pid',item.id);window.location.href='/property-details'}} >View Details</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            ) }

                    </div>


                    {/*Property filter Start*/}
                    {/*<div className="property-filter-area row custom-gutter">*/}
                    {/*    <div className="gallery-sizer col-1" />*/}
                    {/*    /!*property item Start*!/*/}
                    {/*    { property?.map( ( item, i )=>*/}
                    {/*        <div key={ i } className={"rld-filter-item  col-lg-3 col-sm-6"}>*/}
                    {/*            <div className="single-feature">*/}
                    {/*                <div className="thumb">*/}
                    {/*                    <img src={ item.imageUrl1 } alt="img" />*/}
                    {/*                </div>*/}
                    {/*                <div className="details">*/}
                    {/*                    /!*<a href="#" className="feature-logo">*!/*/}
                    {/*                    /!*    <img src={ publicUrl+item.icon } alt={ imagealt } />*!/*/}
                    {/*                    /!*</a>*!/*/}
                    {/*                    <p className="author"><i className="fa fa-user" /> { item.imageUrl1 }</p>*/}
                    {/*                    <h6 className="title readeal-top"><Link to={ item.url }>{ item.name }</Link></h6>*/}
                    {/*                    <h6 className="price">{ item.price }</h6>*/}
                    {/*                    <ul className="contact-list">*/}
                    {/*                        <li><a className="phone" onClick={()=>{deleteProperty(item.id)}}><i style={{color: 'red'}} className="fa fa-trash" /></a></li>*/}
                    {/*                        <li className="readeal-top"><Link className="btn btn-yellow" to={ item.url } >View Details</Link></li>*/}
                    {/*                    </ul>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    ) }*/}
                    {/*</div>*/}
                </div>
            </div>
        </>
    )
}

export default Property