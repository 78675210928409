export function convertNumber(number) {
  if (number < 1000) {
    return number.toString();
  } else if (number < 100000) {
    return (number / 1000).toFixed(1) + "k";
  } else if (number < 10000000) {
    return (number / 100000).toFixed(1) + "L";
  } else {
    return (number / 10000000).toFixed(1) + "Cr";
  }
}
