import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import sectiondata from "../../data/sections.json";
import Api from "../../apis/apis";
import Select from "react-select";
import "./banner.css";
import { convertNumber } from "../utils";

function Banner() {
  const history = useHistory();

  let tempStates = [];
  let [states, setStates] = useState([]);

  let tempCities = [];
  let [cities, setCities] = useState(null);

  let tempCategories = [];
  let [cate, setCate] = useState(null);

  useEffect(async () => {
    let res = await Api.allStates();
    if (res.status == "200") {
      for (let i = 0; i < res.data.length; i++) {
        tempStates.push({ value: res.data[i].id, label: res.data[i].name });
      }
      setStates(tempStates);
    }

    let res1 = await Api.allCities();
    if (res1.status == "200") {
      let cityObj = {};
      res1.data.forEach((item) => {
        let option = { value: item.id, label: item.name };
        cityObj[item.stateId] = cityObj[item.stateId]
          ? [...cityObj[item.stateId], option]
          : [option];
      });
      for (let i = 0; i < res1.data.length; i++) {
        tempCities.push({ value: res1.data[i].id, label: res1.data[i].name });
      }
      setCities(cityObj);
    }

    let data = {
      type: "sell",
    };

    let res2 = await Api.allCategoriesByType(data);
    if (res2.status == "200") {
      for (let i = 0; i < res2.data.length; i++) {
        tempCategories.push({
          value: res2.data[i].id,
          label: res2.data[i].name,
        });
      }
      setCate(tempCategories);
      setTypeF("sell");
    }
  }, []);

  const priceOption = [
    { value: 3000, label: "3000" },
    { value: 5000, label: "5000" },
    { value: 8000, label: "8000" },
    { value: 10000, label: "10000" },
    { value: 50000, label: "50000" },
    { value: 80000, label: "80000" },
    { value: 100000, label: "100000" },
    { value: 500000, label: "500000" },
    { value: 800000, label: "800000" },
    { value: 1000000, label: "1000000" },
    { value: 5000000, label: "5000000" },
    { value: 8000000, label: "8000000" },
    { value: 10000000, label: "10000000" },
    { value: 50000000, label: "50000000" },
    { value: 80000000, label: "80000000" },
    { value: 100000000, label: "100000000" },
  ];

  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";
  let data = sectiondata.banner;

  let [city, setCity] = useState(null);

  const searchData = JSON.parse(sessionStorage.getItem("searchForm") || "{}");
  let [typeF, setTypeF] = useState("sell");
  let [stateF, setStateF] = useState(searchData.state || null);
  let [cityF, setCityF] = useState(searchData.city || null);
  let [priceF, setPriceF] = useState(searchData.price || null);
  let [propertyF, setPropertyF] = useState(searchData.property || null);
  // console.log(stateF,'sateF')
  const inlineStyle = {
    backgroundImage: "url(" + publicUrl + "/assets/img/banner/1.jpg)",
    //backgroundColor: "#cccccc",
  };

  useEffect(async () => {
    let res1 = await Api.allCategories();
    if (res1.status == "200") {
      setCity(res1.data);
    }
  }, []);

  async function submitForm(e) {
    e.preventDefault();

    let newFormdata = {
      type: typeF,
      ...(stateF?.value ? { state: stateF } : {}),
      ...(priceF ? { price: priceF } : {}),
      city: cityF,
      property: propertyF,
    };

    sessionStorage.setItem("search", JSON.stringify(newFormdata));
    // history.push("/search-grid");
    window.location.href = "/search-grid";
    // let res=await Api.searchProperty(data);
    //
    // if (res.status == '200')
    // {
    //   setProperty(res.data);
    //   setCount(res.count);
    //   console.log(res.data)
    // }
    // else
    // {
    //   alert('No Record Found');
    // }

    // console.log(data);
    return;
  }

  async function setTypeFunction(type) {
    let data = {
      type: type,
    };

    let res2 = await Api.allCategoriesByType(data);
    if (res2.status == "200") {
      for (let i = 0; i < res2.data.length; i++) {
        tempCategories.push({
          value: res2.data[i].id,
          label: res2.data[i].name,
        });
      }
      setCate(tempCategories);
      setTypeF(type);
    }
  }

  // console.log(stateF?cities[stateF.value]:[],"cities")
  return (
    <>
      <div className="banner-area jarallax">
        <div className="middlesection">
          <div className="container">
            <div className="banner-inner-wrap1">
              <div className="row">
                <div className="col-12">
                  <div className="banner-inner">
                    <h5 className="sub-title">{data.subtitle}</h5>
                    <h1 className="title" style={{ fontSize: "27px" }}>
                      {data.title1} <br /> {data.title2}
                    </h1>
                  </div>
                </div>
                <div className="col-12">
                  <div className="banner-search-wrap">
                    <ul className="nav nav-tabs rld-banner-tab">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-toggle="tab"
                          href="#tabs_1"
                          onClick={async () => {
                            await setTypeFunction("sell");
                          }}
                        >
                          For Buy
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link btn-green-home"
                          data-toggle="tab"
                          href="#tabs_2"
                          onClick={async () => {
                            await setTypeFunction("rent");
                          }}
                        >
                          For Rent
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div className="tab-pane fade show active" id="tabs_1">
                        <div className="rld-main-search custompadding">
                          <div className="row">
                            {/*<div className="col-xl-4 col-lg-6 col-md-6">*/}
                            {/*  /!*<div className="rld-single-input left-icon">*!/*/}
                            {/*  /!*  /!*<input type="text" placeholder="Entry Landmark Location" />*!/*!/*/}
                            {/*  /!*</div>*!/*/}
                            {/*</div>*/}
                            <div className="col-xl-3 col-lg-3 col-md-2 col-sm-3">
                              <div className="rld-single-select">
                                <label>State</label>
                                <Select
                                  onChange={setStateF}
                                  value={stateF}
                                  options={states}
                                />
                              </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-2 col-sm-3">
                              <div className="rld-single-select">
                                <label>City</label>
                                <Select
                                  isMulti
                                  onChange={setCityF}
                                  options={stateF ? cities[stateF.value] : []}
                                />
                                {/*<select className="select single-select" onChange={(e)=>setRoomF(e.target.value)}>*/}
                                {/*  <option value={'1'}>Room</option>*/}
                                {/*  <option value={'1'}>1</option>*/}
                                {/*  <option value={'2'}>2</option>*/}
                                {/*  <option value={'3'}>3</option>*/}
                                {/*  <option value={'4'}>4</option>*/}
                                {/*  <option value={'5'}>5</option>*/}
                                {/*  <option value={'6'}>6</option>*/}
                                {/*  <option value={'7'}>7</option>*/}
                                {/*  <option value={'8'}>8</option>*/}
                                {/*  <option value={'9'}>9</option>*/}
                                {/*  <option value={'10'}>10</option>*/}
                                {/*</select>*/}
                              </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-3">
                              <div className="rld-single-select">
                                {/*<select className="select single-select" onChange={(e)=>setBathF(e.target.value)}>*/}
                                <label>Type</label>
                                <Select
                                  isMulti
                                  onChange={setPropertyF}
                                  options={cate}
                                />
                                {/*  <option value={'1'}>Bathroom</option>*/}
                                {/*  <option value={'1'}>1</option>*/}
                                {/*  <option value={'2'}>2</option>*/}
                                {/*  <option value={'3'}>3</option>*/}
                                {/*  <option value={'4'}>4</option>*/}
                                {/*  <option value={'5'}>5</option>*/}
                                {/*  <option value={'6'}>6</option>*/}
                                {/*  <option value={'7'}>7</option>*/}
                                {/*  <option value={'8'}>8</option>*/}
                                {/*  <option value={'9'}>9</option>*/}
                                {/*  <option value={'10'}>10</option>*/}
                                {/*</select>*/}
                              </div>
                            </div>

                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-3">
                              <div className="rld-single-select">
                                <label>Price</label>
                                {/* Price slider */}
                                <div className="CustomPriceSlider">
                                  <input
                                    type="range"
                                    min={3000}
                                    max={100000000}
                                    required
                                    // value={priceF}
                                    onChange={(e) => setPriceF(e.target.value)}
                                  />
                                  {priceF && (
                                    <div>
                                      <i>Price: {convertNumber(priceF)} INR</i>
                                    </div>
                                  )}
                                </div>
                                {/* End of Price Slider */}
                                {/* <Select
                                  onChange={setPriceF}
                                  options={priceOption}
                                  defaultValue={"Select State"}
                                /> */}
                                {/*<select className="select single-select" onChange={(e)=>setPriceF(e.target.value)}>*/}
                                {/*  <option value={''}>Price</option>*/}
                                {/*  <option value={'70'}>Under 1000</option>*/}
                                {/*  <option value={'30000'}>Under 5000</option>*/}
                                {/*  <option value={'60000'}>Under 10000</option>*/}
                                {/*  <option value={'90000'}>Under 50000</option>*/}
                                {/*  <option value={'100000'}>Under 100000</option>*/}
                                {/*  <option value={'500000'}>Under 500000</option>*/}
                                {/*  <option value={'800000'}>Under 800000</option>*/}
                                {/*  <option value={'1000000'}>Under 1000000</option>*/}
                                {/*  <option value={'5000000'}>Under 5000000</option>*/}
                                {/*  <option value={'5000000'}>Under 8000000</option>*/}
                                {/*  <option value={'10000000'}>Under 10000000</option>*/}
                                {/*  <option value={'10000000'}>Under 50000000</option>*/}
                                {/*  <option value={'10000000'}>Under 50000000</option>*/}
                                {/*  <option value={'10000000'}>Under 100000000</option>*/}
                                {/*  <option value={'10000000'}>Under 500000000</option>*/}
                                {/*  <option value={'10000000'}>Under 800000000</option>*/}
                                {/*</select>*/}
                              </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-3 readeal-top">
                              <label style={{ visibility: "hidden" }}>
                                Type
                              </label>
                              <Link
                                className="btn btn-yellow customSearchbtn"
                                to="/search-grid"
                                onClick={submitForm}
                              >
                                SEARCH
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="tabs_2">
                        <div className="rld-main-search custompadding">
                          <div className="row">
                            {/*<div className="col-xl-4 col-lg-6 col-md-6">*/}
                            {/*  /!*<div className="rld-single-input left-icon">*!/*/}
                            {/*  /!*  /!*<input type="text" placeholder="Entry Landmark Location" />*!/*!/*/}
                            {/*  /!*</div>*!/*/}
                            {/*</div>*/}
                            <div className="col-xl-3 col-lg-3 col-md-2 col-sm-3">
                              <div className="rld-single-select">
                                <label>State</label>
                                <Select onChange={setStateF} options={states} />
                              </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-2 col-sm-3">
                              <div className="rld-single-select">
                                <label>City</label>
                                <Select
                                  isMulti
                                  onChange={setCityF}
                                  options={stateF ? cities[stateF.value] : []}
                                />
                                {/*<select className="select single-select" onChange={(e)=>setRoomF(e.target.value)}>*/}
                                {/*  <option value={'1'}>Room</option>*/}
                                {/*  <option value={'1'}>1</option>*/}
                                {/*  <option value={'2'}>2</option>*/}
                                {/*  <option value={'3'}>3</option>*/}
                                {/*  <option value={'4'}>4</option>*/}
                                {/*  <option value={'5'}>5</option>*/}
                                {/*  <option value={'6'}>6</option>*/}
                                {/*  <option value={'7'}>7</option>*/}
                                {/*  <option value={'8'}>8</option>*/}
                                {/*  <option value={'9'}>9</option>*/}
                                {/*  <option value={'10'}>10</option>*/}
                                {/*</select>*/}
                              </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-3">
                              <div className="rld-single-select">
                                {/*<select className="select single-select" onChange={(e)=>setBathF(e.target.value)}>*/}
                                <label>Type</label>
                                <Select
                                  isMulti
                                  onChange={setPropertyF}
                                  options={cate}
                                />
                                {/*  <option value={'1'}>Bathroom</option>*/}
                                {/*  <option value={'1'}>1</option>*/}
                                {/*  <option value={'2'}>2</option>*/}
                                {/*  <option value={'3'}>3</option>*/}
                                {/*  <option value={'4'}>4</option>*/}
                                {/*  <option value={'5'}>5</option>*/}
                                {/*  <option value={'6'}>6</option>*/}
                                {/*  <option value={'7'}>7</option>*/}
                                {/*  <option value={'8'}>8</option>*/}
                                {/*  <option value={'9'}>9</option>*/}
                                {/*  <option value={'10'}>10</option>*/}
                                {/*</select>*/}
                              </div>
                            </div>

                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-3">
                              <div className="rld-single-select">
                                <label>Price</label>
                                {/* Price slider */}
                                <div className="CustomPriceSlider">
                                  <input
                                    type="range"
                                    min={3000}
                                    max={100000000}
                                    required
                                    // value={priceF}
                                    onChange={(e) => setPriceF(e.target.value)}
                                  />
                                  {priceF && (
                                    <div>
                                      <i>Price: {convertNumber(priceF)} INR</i>
                                    </div>
                                  )}
                                </div>
                                {/* End of Price Slider */}
                                {/* <Select
                                  onChange={setPriceF}
                                  options={priceOption}
                                  defaultValue={"Select State"}
                                /> */}
                                {/*<select className="select single-select" onChange={(e)=>setPriceF(e.target.value)}>*/}
                                {/*  <option value={''}>Price</option>*/}
                                {/*  <option value={'70'}>Under 1000</option>*/}
                                {/*  <option value={'30000'}>Under 5000</option>*/}
                                {/*  <option value={'60000'}>Under 10000</option>*/}
                                {/*  <option value={'90000'}>Under 50000</option>*/}
                                {/*  <option value={'100000'}>Under 100000</option>*/}
                                {/*  <option value={'500000'}>Under 500000</option>*/}
                                {/*  <option value={'800000'}>Under 800000</option>*/}
                                {/*  <option value={'1000000'}>Under 1000000</option>*/}
                                {/*  <option value={'5000000'}>Under 5000000</option>*/}
                                {/*  <option value={'5000000'}>Under 8000000</option>*/}
                                {/*  <option value={'10000000'}>Under 10000000</option>*/}
                                {/*  <option value={'10000000'}>Under 50000000</option>*/}
                                {/*  <option value={'10000000'}>Under 50000000</option>*/}
                                {/*  <option value={'10000000'}>Under 100000000</option>*/}
                                {/*  <option value={'10000000'}>Under 500000000</option>*/}
                                {/*  <option value={'10000000'}>Under 800000000</option>*/}
                                {/*</select>*/}
                              </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-3 readeal-top">
                              <label style={{ visibility: "hidden" }}>
                                Type
                              </label>
                              <Link
                                className="btn btn-yellow v"
                                to="/search-grid"
                                onClick={submitForm}
                              >
                                SEARCH
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner;
