import React, {Component, useEffect, useState} from 'react';
import sectiondata from '../data/sections.json';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import Api from "../apis/apis";
import { Chart } from "react-google-charts";


function TrendSection() {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'
    let data = sectiondata.property

    let [trends,setTrends]=useState(null);





    useEffect(async ()=>{



        let res = await Api.allTrends(data);

        if (res.status == '200')
        {
            setTrends(res.data)
        }
    },[])




    return(
        <>
            <div className="property-area pd-top-100">
                <div className="container-fluid">
                    <div class='row'>

                        { trends?.map( ( item, i )=>
                            <div className="col-lg-6 col-md-6 col-sm-12" >
                                <div>
                                    <h5 className={'pl-5'}><b>{item.name}</b></h5>
                                    <Chart
                                        chartType="LineChart"
                                        width="100%"
                                        height="400px"
                                        data={
                                            [
                                                ["Year", "Price"],
                                                [parseInt(item.year1), parseInt(item.price1)],
                                                [parseInt(item.year2), parseInt(item.price2)],
                                                [parseInt(item.year3), parseInt(item.price3)],
                                                [parseInt(item.year4), parseInt(item.price4)],
                                                [parseInt(item.year5), parseInt(item.price5)],
                                            ]
                                        }
                                        options={
                                            {
                                                curveType: "function",
                                                legend: {position: "bottom"}
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        ) }

                    </div>


                    {/*Property filter Start*/}
                    {/*<div className="property-filter-area row custom-gutter">*/}
                    {/*    <div className="gallery-sizer col-1" />*/}
                    {/*    /!*property item Start*!/*/}
                    {/*    { property?.map( ( item, i )=>*/}
                    {/*        <div key={ i } className={"rld-filter-item  col-lg-3 col-sm-6"}>*/}
                    {/*            <div className="single-feature">*/}
                    {/*                <div className="thumb">*/}
                    {/*                    <img src={ item.imageUrl1 } alt="img" />*/}
                    {/*                </div>*/}
                    {/*                <div className="details">*/}
                    {/*                    /!*<a href="#" className="feature-logo">*!/*/}
                    {/*                    /!*    <img src={ publicUrl+item.icon } alt={ imagealt } />*!/*/}
                    {/*                    /!*</a>*!/*/}
                    {/*                    <p className="author"><i className="fa fa-user" /> { item.imageUrl1 }</p>*/}
                    {/*                    <h6 className="title readeal-top"><Link to={ item.url }>{ item.name }</Link></h6>*/}
                    {/*                    <h6 className="price">{ item.price }</h6>*/}
                    {/*                    <ul className="contact-list">*/}
                    {/*                        <li><a className="phone" onClick={()=>{deleteProperty(item.id)}}><i style={{color: 'red'}} className="fa fa-trash" /></a></li>*/}
                    {/*                        <li className="readeal-top"><Link className="btn btn-yellow" to={ item.url } >View Details</Link></li>*/}
                    {/*                    </ul>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    ) }*/}
                    {/*</div>*/}
                </div>
            </div>
        </>
    )
}

export default TrendSection