import React, { Component, useEffect, useState } from "react";
import sectiondata from "../../data/sections.json";
import { useHistory } from "react-router-dom";
import Api from "../../apis/apis";
import Select from "react-select";

function AddNew() {
  let history = useHistory();

  let tempStates = [];
  let [states, setStates] = useState(null);

  let tempCities = [];
  let [cities, setCities] = useState(null);

  let publicUrl = process.env.PUBLIC_URL + "/";
  let tempCategories = [];

  let [category, setCategory] = useState(null);

  let [name, setName] = useState(null);
  let [desc, setDesc] = useState(null);
  let [categoryId, setCategoryId] = useState(null);
  let [rooms, setRooms] = useState(0);
  let [bathrooms, setBathrooms] = useState(0);
  let [parking, setParking] = useState(0);
  let [furnished, setFurnished] = useState(null);
  let [price, setPrice] = useState(null);
  let [area, setArea] = useState(null);
  let [annualIncrement, setAnnualIncrement] = useState(null);
  let [type, setType] = useState(null);
  let [longitude, setLongitude] = useState(0);
  let [latitude, setLatitude] = useState(0);
  let [ownerName, setOwnerName] = useState(null);
  let [ownerEmail, setOwnerEmail] = useState(null);
  let [ownerPhone, setOwnerPhone] = useState(null);
  let [images, setImages] = useState(null);
  let [address, setAddress] = useState(null);
  let [houseAddressPic, setHouseAddressPic] = useState(null);
  let [cityId, setCityId] = useState(null);
  let [stateId, setStateId] = useState(null);

  // let [cities,setCities]=useState(null);
  // let [states,setStates]=useState(null);

  let [selectSell, setSelectSell] = useState(false);
  let [selectRent, setSelectRent] = useState(false);

  useEffect(async () => {
    if (sessionStorage.getItem("auth")) {
      // let res=await Api.allCategories();
      // if (res.status == '200')
      // {
      //   setCategory(res.data);
      // }

      let res = await Api.allStates();
      if (res.status == "200") {
        for (let i = 0; i < res.data.length; i++) {
          tempStates.push({ value: res.data[i].id, label: res.data[i].name });
        }
        setStates(tempStates);
      }

      let res1 = await Api.allCities();
      if (res1.status == "200") {
        for (let i = 0; i < res1.data.length; i++) {
          tempCities.push({ value: res1.data[i].id, label: res1.data[i].name });
        }
        setCities(tempCities);
      }
    } else {
      alert('Login first to add property')
      history.push('/');
      window.location.reload();
    }
  }, []);

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  function showPosition(position) {
    setLatitude(position.coords.latitude);
    setLongitude(position.coords.longitude);
    alert("Location Fetched Successfully");
  }

  async function submitForm(e) {
    e.preventDefault();

    if (images.length > 3 || images.length < 3) {
      alert("Images should not be more or less than 3");
      return;
    } else {
      let data = {
        userId: JSON.parse(sessionStorage.getItem("auth"))?.id,
        name: name,
        desc: desc,
        categoryId: categoryId.value,
        address: address,
        rooms: rooms,
        bathrooms: bathrooms,
        parking: parking,
        furnished: furnished,
        price: price,
        area: area,
        annualIncrement: annualIncrement,
        type: type,
        longitude: longitude,
        latitude: latitude,
        ownerName: ownerName,
        ownerEmail: ownerEmail,
        ownerPhone: ownerPhone,
        cityId: cityId.value,
        stateId: stateId.value,
      };

      let res = await Api.addProperty(data);

      if (res.status == "200") {
        for (let i = 0; i < 3; i++) {
          if (i == 0) {
            let params = new FormData();
            params.append("id", res.data.id); //append the values with key, value pair
            params.append("img", images[i]);

            let res1 = await Api.savePropertyImage1(params);
            if (res1.status == "200") {
              console.log("done");
            }
          } else if (i == 1) {
            let params = new FormData();
            params.append("id", res.data.id); //append the values with key, value pair
            params.append("img", images[i]);

            let res1 = await Api.savePropertyImage2(params);
            if (res1.status == "200") {
              console.log("done");
            }
          } else if (i == 2) {
            let params = new FormData();
            params.append("id", res.data.id); //append the values with key, value pair
            params.append("img", images[i]);

            let res1 = await Api.savePropertyImage3(params);
            if (res1.status == "200") {
              console.log("done");
            }
          } else if (i == 3) {
            let params = new FormData();
            params.append("id", res.data.id); //append the values with key, value pair
            params.append("img", images[i]);

            let res1 = await Api.savePropertyImage4(params);
            if (res1.status == "200") {
              console.log("done");
            }
          } else {
            let params = new FormData();
            params.append("id", res.data.id); //append the values with key, value pair
            params.append("img", images[i]);

            let res1 = await Api.savePropertyImage5(params);
            if (res1.status == "200") {
              console.log("done");
            }
          }
        }

        let params = new FormData();
        params.append("id", res.data.id); //append the values with key, value pair
        params.append("img", houseAddressPic[0]);

        let res1 = await Api.saveHouseAddressImage(params);
        if (res1.status == "200") {
          console.log("done");
        }

        alert("Property added");
        // history.push('/property');
        window.location.href = "/property";
        // window.location.reload();
        // window.location.reload();
      }
    }
  }

  function redirect() {
    history.push("/property");
    window.location.reload();
  }

  function resetForm() {
    setName(null);
    setDesc(null);
    setCategoryId(null);
    setRooms(null);
    setBathrooms(null);
    setParking(null);
    setFurnished(null);
    setPrice(null);
    setArea(null);
    setAnnualIncrement(null);
    setType(null);
    setLongitude(null);
    setLatitude(null);
    setOwnerName(null);
    setOwnerEmail(null);
    setOwnerPhone(null);
    setImages(null);
    setStateId(null);
    setCityId(null);
  }

  async function setTypeFunction(type) {
    let data = {
      type: type,
    };

    let res2 = await Api.allCategoriesByType(data);
    if (res2.status == "200") {
      for (let i = 0; i < res2.data.length; i++) {
        tempCategories.push({
          value: res2.data[i].id,
          label: res2.data[i].name,
        });
      }
      setCategory(tempCategories);
      setType(type);
    }
  }

  return (
    <>
      <div className="add-new-property-area pd-top-90 mg-bottom-100">
        <div className="container">
          <div className="row justify-content-center">
            <form onSubmit={submitForm}>
              <div className="col-xl-9 col-lg-10">
                <div className="section-title text-center">
                  <h3>Add New Property</h3>
                </div>
                <div className="border-bottom mb-4">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="single-intro style-two text-center">
                        <div className="thumb">1</div>
                        <div className="details">
                          <h4 className="title">Choose Listing</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="single-intro style-two text-center">
                        <div className="thumb">2</div>
                        <div className="details">
                          <h4 className="title">Add Information</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="single-intro style-two text-center">
                        <div className="thumb">3</div>
                        <div className="details">
                          <h4 className="title">Publish</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-5">
                    <div className="section-title mb-md-0">
                      <h4 className="pt-lg-1 pt-2">Want To?</h4>
                    </div>
                  </div>
                  <div className="col-7 text-right add-property-btn-wrap">
                    <a
                      style={{ color: "white" }}
                      className={
                        selectSell
                          ? "btn btn-success mr-md-3"
                          : "btn btn-yellow mr-md-3"
                      }
                      onClick={async () => {
                        setSelectSell(true);
                        setSelectRent(false);
                        await setTypeFunction("sell");
                      }}
                    >
                      Sell
                    </a>
                    <a
                      style={{ color: "white" }}
                      className={
                        selectRent ? "btn btn-success" : "btn btn-yellow"
                      }
                      onClick={async () => {
                        setSelectSell(false);
                        setSelectRent(true);
                        await setTypeFunction("rent");
                      }}
                    >
                      Rent
                    </a>
                  </div>
                </div>

                {type == null ? (
                  ""
                ) : (
                  <>
                    <div className="row pd-top-100">
                      <div className="col-md-4">
                        <div className="section-title">
                          <h4>
                            <img
                              src={publicUrl + "assets/img/icons/28.png"}
                              alt="img"
                            />
                            House Details
                          </h4>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="section-title">
                          {/*<h4>Jason Landville Apartments</h4>*/}
                          <input
                            className={"form-control"}
                            type={"text"}
                            placeholder={"Enter Title"}
                            required
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                          />
                          {/*<p>Lorem ipsum dolor sit amet, consectetur adipiscing </p>*/}
                          <textarea
                            className={"form-control mt-2"}
                            rows={5}
                            maxlength="300"
                            placeholder={"Enter Description"}
                            required
                            value={desc}
                            onChange={(e) => {
                              if (e.target.value?.length <= 300) {
                                setDesc(e.target.value);
                              }
                            }}
                          ></textarea>
                          <div className="customCounSec">
                            <span className="numberofChar">
                              {desc?.length || 0}
                            </span>
                            /<span className="charMaxLength">300</span>
                          </div>
                          <textarea
                            className={"form-control mt-2"}
                            rows={5}
                            maxlength="300"
                            placeholder={"Enter House Address"}
                            required
                            value={address}
                            onChange={(e) => {
                              if (e.target.value?.length <= 300) {
                                setAddress(e.target.value);
                              }
                            }}
                          ></textarea>
                          <div className="customCounSec">
                            <span className="numberofChar">
                              {address?.length || 0}
                            </span>
                            /<span className="charMaxLength">300</span>
                          </div>
                          {/*<input className={'form-control mt-2'} type={'number'} placeholder={'Enter Area in Sq/Feet'}/>*/}
                        </div>
                        <div className="row">
                          <div className="col-lg-6 mb-3">
                            <div className="rld-single-select">
                              <Select onChange={setStateId} options={states} />
                              {/*<select className="select single-select" required onChange={(e)=>{setStateId(e.target.value)}}>*/}
                              {/*  <option value={'null'}>Select State</option>*/}
                              {/*  {*/}
                              {/*    states?.map((itm,i)=>(*/}
                              {/*        <option value={itm.id}>{itm.name}</option>*/}
                              {/*    ))*/}
                              {/*  }*/}

                              {/*</select>*/}
                            </div>
                          </div>
                          <div className="col-lg-6 mb-3">
                            <div className="rld-single-select">
                              {/*<select className="select single-select" required onChange={(e)=>{setCityId(e.target.value)}}>*/}
                              {/*  <option value={'null'}>Select Cities</option>*/}
                              {/*  {*/}
                              {/*    cities?.map((itm,i)=>(*/}
                              {/*        <option value={itm.id}>{itm.name}</option>*/}
                              {/*    ))*/}
                              {/*  }*/}

                              {/*</select>*/}
                              <Select onChange={setCityId} options={cities} />
                            </div>
                          </div>
                          <div className="col-lg-6 mb-3">
                            <div className="rld-single-select">
                              {/*<select className="select single-select" required onChange={(e)=>{setCategoryId(e.target.value)}}>*/}
                              {/*  <option value={'null'}>Select Category</option>*/}
                              {/*  {*/}
                              {/*    category?.map((itm,i)=>(*/}
                              {/*        <option style={{color:"black"}} value={itm.id}>{itm.name}</option>*/}
                              {/*    ))*/}
                              {/*  }*/}

                              {/*</select>*/}
                              <Select
                                onChange={setCategoryId}
                                options={category}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 mb-3">
                            <div className="rld-single-select">
                              <select
                                className="select single-select"
                                onChange={(e) => {
                                  setRooms(e.target.value);
                                }}
                              >
                                <option value={"0"}>Select Rooms</option>
                                <option value={"1"}>1</option>
                                <option value={"3"}>2</option>
                                <option value={"3"}>3</option>
                                <option value={"4"}>4</option>
                                <option value={"5"}>5</option>
                                <option value={"6"}>6</option>
                                <option value={"7"}>7</option>
                                <option value={"8"}>8</option>
                                <option value={"9"}>9</option>
                                <option value={"10"}>10</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-3">
                            <div className="rld-single-select">
                              <select
                                className="select single-select"
                                onChange={(e) => {
                                  setBathrooms(e.target.value);
                                }}
                              >
                                <option value={"0"}>Select Bathrooms</option>
                                <option value={"1"}>1</option>
                                <option value={"3"}>2</option>
                                <option value={"3"}>3</option>
                                <option value={"4"}>4</option>
                                <option value={"5"}>5</option>
                                <option value={"6"}>6</option>
                                <option value={"7"}>7</option>
                                <option value={"8"}>8</option>
                                <option value={"9"}>9</option>
                                <option value={"10"}>10</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-3">
                            <div className="rld-single-select">
                              <select
                                className="select single-select"
                                onChange={(e) => {
                                  setParking(e.target.value);
                                }}
                              >
                                <option value={"0"}>Select Parking</option>
                                <option value={"1"}>1</option>
                                <option value={"3"}>2</option>
                                <option value={"3"}>3</option>
                                <option value={"4"}>4</option>
                                <option value={"5"}>5</option>
                                <option value={"6"}>6</option>
                                <option value={"7"}>7</option>
                                <option value={"8"}>8</option>
                                <option value={"9"}>9</option>
                                <option value={"10"}>10</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-3">
                            <div className="rld-single-select">
                              <select
                                className="select single-select"
                                onChange={(e) => {
                                  setFurnished(e.target.value);
                                }}
                              >
                                <option value={null}>Select Furnished</option>
                                <option value={"yes"}>Yes</option>
                                <option value={"no"}>No</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="section-title">
                          <input
                            className={"form-control mt-2"}
                            type={"number"}
                            placeholder={"Enter Price"}
                            required
                            onChange={(e) => {
                              setPrice(e.target.value);
                            }}
                            value={price}
                          />
                          <input
                            className={"form-control mt-2"}
                            type={"number"}
                            placeholder={"Enter Area in Sq/Feet"}
                            required
                            onChange={(e) => {
                              setArea(e.target.value);
                            }}
                            value={area}
                          />
                          {type == "rent" ? (
                            <input
                              className={"form-control mt-2"}
                              type={"number"}
                              placeholder={"Enter Annual Increment"}
                              required
                              onChange={(e) => {
                                setAnnualIncrement(e.target.value);
                              }}
                              value={annualIncrement}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row pd-top-100">
                      <div className="col-md-4">
                        <div className="section-title">
                          <h4>
                            <img
                              src={publicUrl + "assets/img/icons/5.png"}
                              alt="img"
                            />
                            Owner Details
                          </h4>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="section-title">
                          {/*<h4>Jason Landville Apartments</h4>*/}
                          <input
                            className={"form-control"}
                            type={"text"}
                            placeholder={"Enter Name"}
                            required
                            onChange={(e) => {
                              setOwnerName(e.target.value);
                            }}
                            value={ownerName}
                          />
                          <input
                            className={"form-control mt-2"}
                            type={"email"}
                            placeholder={"Enter Email"}
                            required
                            onChange={(e) => {
                              setOwnerEmail(e.target.value);
                            }}
                            value={ownerEmail}
                          />
                          <input
                            className={"form-control mt-2"}
                            type={"tel"}
                            placeholder={"Enter WhatsApp/Phone Number"}
                            max={10}
                            min={10}
                            maxLength={10}
                            minLength={10}
                            required
                            onChange={(e) => {
                              setOwnerPhone(e.target.value);
                            }}
                            value={ownerPhone}
                          />
                          {/*<textarea className={'form-control mt-2'} rows={5} placeholder={'Enter Address'} required onChange={(e)=>{setOwnerAddress(e.target.value)}} value={ownerAddress}></textarea>*/}
                        </div>
                      </div>
                    </div>

                    <div className="row pd-top-80">
                      <div className="col-md-4">
                        <div className="section-title">
                          <h4>
                            <img
                              src={publicUrl + "assets/img/icons/29.png"}
                              alt="img"
                            />
                            Address
                          </h4>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="section-title">
                          {/*<h4>Address Here</h4>*/}
                          <input
                            required
                            onChange={(e) => {
                              setHouseAddressPic(e.target.files);
                            }}
                            type={"file"}
                            className={"form-control"}
                            multiple
                          />
                          <i>Add picture of google map location</i>
                          <br />
                          <button
                            type={"button"}
                            onClick={getLocation}
                            className={"btn btn-primary"}
                          >
                            Fetch Live Location
                          </button>
                          <div className={"mt-5"}>
                            <p>
                              Longitude:{" "}
                              <input
                                type={"number"}
                                value={longitude}
                                onChange={(e) => setLongitude(e.target.value)}
                                required
                              />
                            </p>
                            <p>
                              Latitude:{" "}
                              <input
                                type={"number"}
                                value={latitude}
                                onChange={(e) => setLatitude(e.target.value)}
                                required
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row pd-top-80">
                      <div className="col-md-4">
                        <div className="section-title">
                          <h4>
                            <img
                              src={publicUrl + "assets/img/icons/26.png"}
                              alt="img"
                            />
                            Pictures
                          </h4>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="section-title">
                          {/*<h4>Address Here</h4>*/}
                          <input
                            required
                            onChange={(e) => {
                              setImages(e.target.files);
                            }}
                            type={"file"}
                            className={"form-control"}
                            multiple
                          />
                          <i>Add exact 3 images</i>
                        </div>
                      </div>
                    </div>
                    <div className="row pd-top-80">
                      <div className="col-md-4">
                        {/*<div className="section-title">*/}
                        {/*  <h4><img src={publicUrl+"assets/img/icons/31.png"} alt="img" />Amenities</h4>*/}
                        {/*</div>*/}
                      </div>
                      <div className="col-md-8">
                        <div className="row">
                          <div className="col-12 mt-5">
                            <button type={"submit"} className="btn btn-yellow">
                              Publish property
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddNew;
