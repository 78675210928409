import React, { useEffect, useState } from "react";
import "./banner.css";
import ReactPlayer from "react-player";
import LSidebarImage1 from "../../assets/ads2.png";
import RSidebarImage1 from "../../assets/ads3.png";
import Bottom from "../../assets/ads4.png";
import Api, { test_url } from "../../apis/apis";
import { Link } from "react-router-dom";
import logo from '../../assets/logo.png'


function BestValueSection() {
  const [bestValue, setBestValue] = useState([]);

  const getBestValue = async () => {
    let res = await Api.getBestValueAction();
    if (res.status == "200") {
      setBestValue(res.data);
    }
  };
  useEffect(() => {
    getBestValue();
  }, []);

  const isImage = (url) => {
    return /\.(jpg|jpeg|png||PNG|webp|avif|gif|svg)$/.test(url);
  };

  return (
    <>
      <div className="BestValueSection">
        {bestValue?.length > 0 && (
          <div className="container">
            <div className="row">
              <div className="col-md-12" style={{ marginTop: "40px" }}>
                <div className="section-title text-center">
                  <h2 className="title text-center">Best Value</h2>
                </div>
              </div>
            </div>
            <div className="row">
              {bestValue?.map((item, index) => (
                <div className="col-md-4" key={index}>
                  <div className="addsImage adsImageFit">
                    {!isImage(item?.imageUrl) ? (
                      <a
                        href={item?.url || "#"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <ReactPlayer
                          responsive
                          width={"100%"}
                          config={{
                            file: {
                              attributes: {
                                poster: logo,
                              },
                            },
                          }}
                          // height={"auto"}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100% !important",
                          }}
                          url={`${test_url}${item?.imageUrl}`}
                          playing
                          muted
                          controls
                          loop
                        />
                      </a>
                    ) : (
                      <a
                        href={item?.url || "#"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={`${test_url}${item?.imageUrl}`}
                          alt={item?.title}
                        />
                      </a>
                    )}
                  </div>
                </div>
              ))}
              {/* <div className="col-md-4">
              <div className="addsImage adsImageFit">
                {" "}
                <img src={Bottom} alt="" />{" "}
              </div>
            </div>
            <div className="col-md-4">
              <div className="addsImage adsImageFit">
                <img src={RSidebarImage1} alt="" />
              </div>
            </div> */}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default BestValueSection;
