import React, { Component, useEffect, useMemo, useState } from "react";
import sectiondata from "../../data/sections.json";
import parse from "html-react-parser";
import Api from "../../apis/apis";
import GoogleMapReact from "google-map-react";

import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
} from "next-share";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
function PropertyDetails() {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";

  let [property, setProperty] = useState(null);
  let { id } = useParams();
  sessionStorage.setItem("pids", id);

  const location = {
    lat: property?.latitude,
    lng: property?.longitude,
  };
  const defaultMapOptions = {
    fullscreenControl: false,
    zoomControl: false,
  };

  const defaultProps = {
    center: {
      lat: 31.5180195,
      lng: 74.3159291,
    },
    zoom: 20,
  };

  useEffect(async () => {
    let data = {
      id: sessionStorage.getItem("pids"),
    };

    let res = await Api.propertyById(data);

    if (res.status == "200") {
      setProperty(res.data);
      console.log(res.data);
    }
  }, []);

  function goToChat() {
    if (JSON.parse(sessionStorage.getItem("auth"))) {
      sessionStorage.setItem(
        "sender",
        JSON.parse(sessionStorage.getItem("auth")).id
      );
      sessionStorage.setItem("reciever", property?.userId);
      window.location.href = "/chat";
    } else {
      alert("Login first in order to chat");
      return;
    }
  }

  return (
    <>
      <div className="property-details-area">
        <div className="bg-gray pd-top-100 pd-bottom-90">
          <div className="container">
            <div className="row ">
              <div className="col-xl-9 col-lg-8">
                <div className="property-details-slider">
                  <div className="item">
                    <div className="thumb">
                      <img
                        className={"detailsImage"}
                        src={property?.imageUrl1}
                        alt={imagealt}
                      />
                    </div>
                  </div>
                  <div className="item">
                    <div className="thumb">
                      <img
                        className={"detailsImage"}
                        src={property?.imageUrl2}
                        alt={imagealt}
                      />
                    </div>
                  </div>
                  <div className="item">
                    <div className="thumb">
                      <img
                        className={"detailsImage"}
                        src={property?.imageUrl3}
                        alt={imagealt}
                      />
                    </div>
                  </div>
                </div>
                <div className="property-details-slider-info">
                  {property?.type == "sell" ? (
                    <>
                      <h3> {property?.name}</h3>
                      <h3>
                        <span>INR </span> {property?.price}
                      </h3>
                      <h3>
                        <span>{property?.category}</span>
                        {property?.type == "sell" ? "For Sell" : "For Rent"}
                      </h3>
                      <h3>
                        <span>{property?.city}</span>({property?.state})
                      </h3>
                    </>
                  ) : (
                    <>
                      <h3>
                        <span>INR {property?.price}/mo</span> {property?.name}
                      </h3>
                      <h3>
                        <span>{property?.category}</span>
                        {property?.type == "sell" ? "For Sell" : "For Rent"}
                      </h3>
                      <p>
                        <b>
                          <span>INR {property?.annualIncrement}/mo</span> Annual
                          Increment
                        </b>
                      </p>
                    </>
                  )}

                  {/*<del>$500</del>*/}
                </div>
              </div>
              <div className="col-xl-3 col-lg-4">
                <div className="widget widget-owner-info mt-lg-0 mt-5">
                  <div className="owner-info text-center">
                    {/*<div className="thumb">*/}
                    {/*  <img src={ publicUrl+"/assets/img/news/21.png"} alt={ imagealt } />*/}
                    {/*</div>*/}
                    <div className="details">
                      <h6>{property?.ownerName}</h6>
                      <span className="designation">Building Owner</span>
                      {/*<p className="reviews"><i className="fa fa-star" /><span>4.8</span> 70 Review</p>*/}
                    </div>
                  </div>

                  {/*<div className="rld-single-input">*/}
                  {/*  <input type="text" placeholder="Full Name" />*/}
                  {/*</div>*/}
                  {/*<div className="rld-single-input">*/}
                  {/*  <input type="text" placeholder="Email" />*/}
                  {/*</div>*/}
                  {/*<div className="rld-single-input">*/}
                  {/*  <input type="text" placeholder="Messages" />*/}
                  {/*</div>*/}
                  {/*<a className="btn btn-yellow mt-4" href={'mailto:'+property?.ownerEmail}>Send Email</a>*/}

                  <div className="contact-info">
                    <h6 className="mb-3">Contact Info</h6>
                    <div className="media">
                      {/*<div className="media-left">*/}
                      {/*  <i className="fa fa-map" />*/}
                      {/*</div>*/}
                      {/*<div className="media-body">*/}
                      {/*  <p>Address</p>*/}
                      {/*  <span>{property?.ownerAddress}</span>*/}
                      {/*</div>*/}
                    </div>
                    <div className="media">
                      <div className="media-left">
                        <i className="fa fa-phone" />
                      </div>
                      <div className="media-body">
                        <p>Phone</p>
                        <span>{property?.ownerPhone}</span>
                      </div>
                    </div>

                    <div className="media mb-0">
                      <div className="media-left">
                        <i className="fa fa-envelope" />
                      </div>
                      <div className="media-body">
                        <p>Email</p>
                        <span>{property?.ownerEmail}</span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <a href={"mailto:" + property?.ownerEmail}>
                        <button
                          type="button"
                          className="btn btn-yellow mt-4"
                          data-toggle="modal"
                          data-target=".bd-example-modal-lg"
                        >
                          <i
                            style={{ fontSize: "20px" }}
                            className={"fa fa-envelope"}
                          />
                        </button>
                      </a>

                      <a href={"https://wa.me/" + property?.ownerPhone}>
                        <button
                          type="button"
                          className="btn btn-yellow mt-4"
                          data-toggle="modal"
                          data-target=".bd-example-modal-lg"
                        >
                          <i
                            style={{ fontSize: "20px" }}
                            className="fa fa-whatsapp"
                          />
                        </button>
                      </a>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <div>
                      <div className="contact-info">
                        <h6 style={{ marginLeft: "15px", marginTop: "-25px" }}>
                          Social Media Share
                        </h6>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            marginTop: "20px",
                          }}
                        >
                          <FacebookShareButton
                            url={window.location.href}
                            quote={
                              "next-share is a social share buttons for your next React apps."
                            }
                            hashtag={"#nextshare"}
                          >
                            <FacebookIcon size={32} round />
                          </FacebookShareButton>
                          <WhatsappShareButton
                            url={window.location.href}
                            quote={
                              "next-share is a social share buttons for your next React apps."
                            }
                            hashtag={"#nextshare"}
                          >
                            <WhatsappIcon size={32} round />
                          </WhatsappShareButton>
                          <EmailShareButton
                            url={window.location.href}
                            quote={
                              "next-share is a social share buttons for your next React apps."
                            }
                            hashtag={"#nextshare"}
                          >
                            <EmailIcon size={32} round />
                          </EmailShareButton>
                          <TwitterShareButton
                            url={window.location.href}
                            quote={
                              "next-share is a social share buttons for your next React apps."
                            }
                            hashtag={"#nextshare"}
                          >
                            <TwitterIcon size={32} round />
                          </TwitterShareButton>
                          <TelegramShareButton
                            url={window.location.href}
                            quote={
                              "next-share is a social share buttons for your next React apps."
                            }
                            hashtag={"#nextshare"}
                          >
                            <TelegramIcon size={32} round />
                          </TelegramShareButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row pd-top-90">
            <div className="col-lg-9">
              <div className="property-info mb-5">
                <div className="row">
                  <div className="col-md-3 col-sm-6">
                    <div className="single-property-info">
                      <h5>Bed Rooms</h5>
                      <p>
                        <i className="fa fa-bed" />
                        {property?.rooms}
                      </p>
                    </div>
                  </div>
                  {/*<div className="col-md-3 col-sm-6">*/}
                  {/*  <div className="single-property-info">*/}
                  {/*    <h5>Bathrooms</h5>*/}
                  {/*    <p><i className="fa fa-bath" />{property?.bathrooms}</p>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  <div className="col-md-3 col-sm-6">
                    <div className="single-property-info">
                      <h5>Area</h5>
                      <p>
                        <i className="fa fa-square"></i> {property?.area} sq.
                        ft.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className="single-property-info">
                      <h5>Parking</h5>
                      <p>
                        <i className="fa fa-car" />
                        {property?.parking}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="property-news-single-card style-two border-bottom-yellow">
                <h4>Description</h4>
                <p>{property?.desc}</p>
                {/*<a href="#">Read More</a>*/}
              </div>
              <div className="property-news-single-card style-two border-bottom-yellow">
                <h4>Location</h4>
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <img src={property?.houseAddressPicUrl} />
                      <br />
                      <br />
                      <b>
                        <p className={"text-center"}>{property?.address}</p>
                      </b>
                      <p className={"text-center"}>
                        <a
                          href={
                            "https://www.google.com/maps/search/?api=1&query=" +
                            property?.latitude +
                            "," +
                            property?.longitude
                          }
                          target={"_blank"}
                        >
                          <button className="btn btn-primary">
                            View on Google Map
                          </button>
                        </a>
                      </p>
                      {/*<GoogleMapReact*/}
                      {/*    bootstrapURLKeys={{ key: "AIzaSyBlkdXZQdmGyJG7fHpSUZ20kfobGl2jiB4" }}*/}
                      {/*    defaultCenter={defaultProps.center}*/}
                      {/*    defaultZoom={defaultProps.zoom}*/}
                      {/*>*/}
                      {/*</GoogleMapReact>*/}
                    </div>
                  </div>
                </div>
                {/*<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5621.1629504770535!2d-122.43633647504856!3d37.748515859182696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80859a6d00690021%3A0x4a501367f076adff!2sSan%20Francisco%2C%20CA%2C%20USA!5e0!3m2!1sen!2sbd!4v1578304196576!5m2!1sen!2sbd" style={{border: 0}} allowFullScreen />*/}
              </div>
              {/*<div className="property-news-single-card border-bottom-yellow">*/}
              {/*  <h4>Amenities</h4>*/}
              {/*  <div className="row">*/}
              {/*<div className="col-sm-4">*/}
              {/*  <ul className="rld-list-style mb-3 mb-sm-0">*/}
              {/*    {(property?.furnished == 'yes')?<li><i className="fa fa-check" /> Furnished</li>:""}*/}
              {/*<li><i className="fa fa-check" /> Poll</li>*/}
              {/*<li><i className="fa fa-check" /> Concierge</li>*/}
              {/*<li><i className="fa fa-check" /> Basketball Cout</li>*/}
              {/*<li><i className="fa fa-check" /> Sprinklers</li>*/}
              {/*  </ul>*/}
              {/*</div>*/}
              {/*<div className="col-sm-4">*/}
              {/*  <ul className="rld-list-style mb-3 mb-sm-0">*/}
              {/*    <li><i className="fa fa-check" /> Recreation</li>*/}
              {/*    <li><i className="fa fa-check" /> Front Yard</li>*/}
              {/*    <li><i className="fa fa-check" /> Wine Cellar</li>*/}
              {/*    <li><i className="fa fa-check" /> Basketball Cout</li>*/}
              {/*    <li><i className="fa fa-check" /> Fireplace</li>*/}
              {/*  </ul>*/}
              {/*</div>*/}
              {/*<div className="col-sm-4">*/}
              {/*  <ul className="rld-list-style mb-3 mb-sm-0">*/}
              {/*    <li><i className="fa fa-check" /> Balcony</li>*/}
              {/*    <li><i className="fa fa-check" /> Pound</li>*/}
              {/*    <li><i className="fa fa-check" /> Deck</li>*/}
              {/*    <li><i className="fa fa-check" /> 24x7 Security</li>*/}
              {/*    <li><i className="fa fa-check" /> Indoor Game</li>*/}
              {/*  </ul>*/}
              {/*</div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*<div className="property-news-single-card border-bottom-yellow">*/}
              {/*  <h4>Floor Plan</h4>*/}
              {/*  <div className="thumb">*/}
              {/*    <img src={ publicUrl+"/assets/img/others/10.png"} alt={imagealt} />*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*<div className="property-news-single-card border-bottom-yellow pb-3">*/}
              {/*  <h4>Facts and Features</h4>*/}
              {/*  <div className="row">*/}
              {/*    <div className="col-md-3 col-sm-6">*/}
              {/*      <div className="single-floor-list media">*/}
              {/*        <div className="media-left">*/}
              {/*          <i className="fa fa-bed" />*/}
              {/*        </div>*/}
              {/*        <div className="media-body">*/}
              {/*          <h6>Living Room</h6>*/}
              {/*          <p>20 x 16 sq feet</p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <div className="col-md-3 col-sm-6">*/}
              {/*      <div className="single-floor-list media">*/}
              {/*        <div className="media-left">*/}
              {/*          <i className="fa fa-car" />*/}
              {/*        </div>*/}
              {/*        <div className="media-body">*/}
              {/*          <h6>Garage</h6>*/}
              {/*          <p>20 x 16 sq feet</p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <div className="col-md-3 col-sm-6">*/}
              {/*      <div className="single-floor-list media">*/}
              {/*        <div className="media-left">*/}
              {/*          <img src={ publicUrl+"/assets/img/icons/7.png"} alt={imagealt} />*/}
              {/*        </div>*/}
              {/*        <div className="media-body">*/}
              {/*          <h6>Dining Area</h6>*/}
              {/*          <p>20 x 16 sq feet</p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <div className="col-md-3 col-sm-6">*/}
              {/*      <div className="single-floor-list media">*/}
              {/*        <div className="media-left">*/}
              {/*          <img src={ publicUrl+"/assets/img/icons/7.png"} alt={imagealt} />*/}
              {/*        </div>*/}
              {/*        <div className="media-body">*/}
              {/*          <h6>Dining Area</h6>*/}
              {/*          <p>20 x 16 sq feet</p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <div className="col-md-3 col-sm-6">*/}
              {/*      <div className="single-floor-list media">*/}
              {/*        <div className="media-left">*/}
              {/*          <i className="fa fa-bed" />*/}
              {/*        </div>*/}
              {/*        <div className="media-body">*/}
              {/*          <h6>Bedroom</h6>*/}
              {/*          <p>20 x 16 sq feet</p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <div className="col-md-3 col-sm-6">*/}
              {/*      <div className="single-floor-list media">*/}
              {/*        <div className="media-left">*/}
              {/*          <i className="fa fa-bath" />*/}
              {/*        </div>*/}
              {/*        <div className="media-body">*/}
              {/*          <h6>Bathroom</h6>*/}
              {/*          <p>20 x 16 sq feet</p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <div className="col-md-3 col-sm-6">*/}
              {/*      <div className="single-floor-list media">*/}
              {/*        <div className="media-left">*/}
              {/*          <img src={ publicUrl+"/assets/img/icons/17.png"} alt={imagealt} />*/}
              {/*        </div>*/}
              {/*        <div className="media-body">*/}
              {/*          <h6>Gym Area</h6>*/}
              {/*          <p>20 x 16 sq feet</p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <div className="col-md-3 col-sm-6">*/}
              {/*      <div className="single-floor-list media">*/}
              {/*        <div className="media-left">*/}
              {/*          <img src={ publicUrl+"/assets/img/icons/17.png"} alt={imagealt} />*/}
              {/*        </div>*/}
              {/*        <div className="media-body">*/}
              {/*          <h6>Gym Area</h6>*/}
              {/*          <p>20 x 16 sq feet</p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*<div className="property-news-single-card border-bottom-yellow mb-0">*/}
              {/*  <h4>3D Gallery</h4>*/}
              {/*  <div className="thumb">*/}
              {/*    <img src={ publicUrl+"/assets/img/others/11.png"}  alt={imagealt} />*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PropertyDetails;
