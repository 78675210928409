import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png'
import user from '../../assets/user.png'
import {useHistory} from 'react-router-dom';


function Navbar() {

    let history=useHistory();
    let publicUrl = process.env.PUBLIC_URL+'/'

    return(
        <>
            <div>
                <div className="navbar-area">
                    <nav className="navbar navbar-area navbar-expand-lg">
                        <div className="container nav-container">
                            <div className="responsive-mobile-menu">
                                <button className="menu toggle-btn d-block d-lg-none" data-toggle="collapse" data-target="#realdeal_main_menu" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="icon-left" />
                                    <span className="icon-right" />
                                </button>
                            </div>
                            <div className="logo readeal-top">
                                <Link onClick={()=>{window.location.href='/'}}><img src={logo} alt="logo" style={{weight: "70px", height: "70px"}} /></Link>
                            </div>
                            <div className="nav-right-part nav-right-part-mobile">
                                <Link className="btn btn-yellow" onClick={()=>{window.location.href='/add-property'}} >ADD LISTING <span className="right"><i className="la la-plus" /></span></Link>
                            </div>
                            <div className="collapse navbar-collapse" id="realdeal_main_menu">
                                <ul className="navbar-nav menu-open readeal-top">
                                    <li className=" current-menu-item">
                                        <Link onClick={()=>{window.location.href='/'}}>Home</Link>
                                        {/*<ul className="sub-menu">*/}
                                        {/*  <li><Link to="/">Home 01</Link></li>*/}
                                        {/*  <li><Link to="/home-v2">Home 02</Link></li>*/}
                                        {/*  <li><Link to="/home-v3">Home 03</Link></li>*/}
                                        {/*  <li><Link to="/home-v4">Home 04</Link></li>*/}
                                        {/*</ul>*/}
                                    </li>
                                    {/*<li className="menu-item-has-children">*/}
                                    {/*  <a href="#">Property</a>*/}
                                    {/*  <ul className="sub-menu">*/}
                                    {/*     <li><Link to="/property">Property</Link></li>*/}
                                    {/*    <li><Link to="/availavbe-property">Propertys Availavbe</Link></li>*/}
                                    {/*    <li><Link to="/properties-by-city">Property By City</Link></li>*/}
                                    {/*    <li><Link to="/recent-properties">Property Recenty</Link></li>*/}
                                    {/*    <li><Link to="/property-details">Property Details</Link></li>*/}
                                    {/*  </ul>*/}
                                    {/*</li>*/}
                                    {/*<li className="menu-item-has-children">*/}
                                    {/*  <a href="#">Pages</a>*/}
                                    {/*  <ul className="sub-menu">*/}
                                    {/*    <li><Link to="/About">About</Link></li>*/}
                                    {/*<li><Link to="/advisor">Advisor</Link></li>*/}
                                    {/*<li><Link to="/search-list">Search List</Link></li>*/}
                                    {/*<li><Link to="/search-grid">Search Grid</Link></li>*/}
                                    {/*<li><Link to="/faq">FAQ</Link></li>*/}
                                    {/*<li><Link to="/pricing">Pricing</Link></li>*/}
                                    {/*<li><Link to="/user-list">User List</Link></li>*/}
                                    {/*<li><Link to="/Registration">Registration</Link></li>*/}
                                    {/*<li><Link to="/error">404</Link></li>*/}
                                    {/*  </ul>*/}
                                    {/*</li>*/}
                                    {/*<li className="menu-item-has-children">*/}
                                    {/*  <a href="#">News</a>*/}
                                    {/*  <ul className="sub-menu">*/}
                                    {/*    <li><Link to="/news">News</Link></li>*/}
                                    {/*    <li><Link to="/news-details">News Details</Link></li>*/}
                                    {/*  </ul>*/}
                                    {/*</li>*/}

                                    <li><Link onClick={()=>{window.location.href='/search-grid'}}>Properties</Link></li>
                                    <li><Link onClick={()=>{window.location.href='/About'}} >About</Link></li>
                                    {/*<li><Link onClick={()=>{window.location.href='/contact'}} >Contact Us</Link></li>*/}
                                    <li><Link onClick={()=>{window.location.href='/Registration'}} >Registration</Link></li>
                                    <li><Link onClick={()=>{window.location.href='/trends'}} >Trends</Link></li>
                                    {
                                        (sessionStorage.getItem('auth'))?
                                            <li><Link onClick={()=>{window.location.href='/property'}} >My</Link></li>
                                            :
                                            ""
                                    }
                                </ul>
                            </div>
                            <div className="nav-right-part nav-right-part-desktop readeal-top">
                                <Link className="btn btn-yellow" onClick={()=>{window.location.href='/add-property'}} >ADD LISTING <span className="right"><i className="la la-plus" /></span></Link>
                            </div>
                            {/*{*/}
                            {/*    sessionStorage.getItem('auth')?*/}
                            {/*        <div className="nav-right-part nav-right-part-desktop readeal-top">*/}
                            {/*            <img className={'ml-3'} src={'https://lh3.googleusercontent.com/a/ALm5wu2bLVHjP8h75FruCMTXlqPoUBsjUBxc-Idhvnmd=s96-c'} style={{width: '40px',height: '40px'}}/>*/}
                            {/*        </div>*/}
                            {/*        :*/}
                            {/*        <div className="nav-right-part nav-right-part-desktop readeal-top">*/}
                            {/*            <img className={'ml-3'} src={user} style={{width: '40px',height: '40px'}}/>*/}
                            {/*        </div>*/}
                            {/*}*/}
                        </div>
                    </nav>
                </div>
            </div>
        </>
    )
}




export default Navbar