import React, {Component, useEffect, useState} from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import Api from "../../apis/apis";


function RecentProperties() {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'
    let data = sectiondata.recentproperties

    let [property,setProperty]=useState(null);

    useEffect(async () => {
        let res=await Api.propertiesForSellLatest();
        if (res.status == '200')
        {
            setProperty(res.data);
        }
    }, []);

    return(
        <>
            {
                property?
                    <div className="properties-area pd-top-92">
                        <div className="container">
                            <div className="section-title">
                                <h2 className="title">{ data.title }</h2>
                                <Link className="btn-view-all" onClick={()=>{window.location.href='/search-grid'}} >View All</Link>
                            </div>
                            <div className="row">
                                { property?.map( ( item, i )=>
                                    <div key={ i } className="col-lg-3 col-sm-6">
                                        <div className="single-feature">
                                        <Link  onClick={()=>{sessionStorage.setItem('pid',item.id);window.location.href=`/property-details/${item.id}`}}>
                                            <div className="thumb">
                                                <img src={ item.imageUrl1 } style={{ height: '250px',width:'250px' }}  alt={ imagealt } />
                                            </div>
                                            <div className="details">
                                                {/*<a href="#" className="feature-logo">*/}
                                                {/*    <img src={publicUrl+item.icon} alt={ imagealt } />*/}
                                                {/*</a>*/}
                                                <p className="author"><i className="fa fa-user" /> { item.ownerName }</p>
                                                <h6 className="title readeal-top"><Link onClick={()=>{sessionStorage.setItem('pid',item.id);window.location.href='/property-details'}}>{ item.name }</Link></h6>
                                                {
                                                    (item.type == 'sell')?
                                                        <>
                                                            <h5 className="price">INR { item.price }</h5><br/>
                                                        </>
                                                        :
                                                        <>
                                                            <h5 className="price">INR { item.price } /mo</h5><br/>
                                                        </>
                                                }
                                                <h6 className="price"><b>For  </b>{ (item.type == 'sell')?'Sell':'Rent' } ({item.category})</h6>
                                                <h6 className="price"><b>Furnished </b>{ (item.furnished == 'yes')?'Yes':'No' }</h6>
                                                <ul className="info-list">
                                                    {/*{ item.features.map( ( features, i )=>*/}
                                                    {/*    <li key={ i } ><i className={ features.icon } /> { features.title }</li>*/}
                                                    {/*) }*/}
                                                    <li><i className="fa fa-bed" /> { item.rooms }</li>
                                                    <li><i className="fa fa-bath" /> { item.bathrooms }</li>
                                                    <li><i className="fa fa-parking" /> { item.parking }</li>
                                                    <li><i className="fa fa-square" /> { item.area } sq.</li>

                                                </ul>
                                                <ul className="contact-list">
                                                    <li style={{cursor: 'pointer'}}><a className="phone" onClick={()=>{sessionStorage.setItem('pid',item.id);window.location.href='/property-details'}}><i className="fa fa-phone" /></a></li>
                                                    <li style={{cursor: 'pointer'}}><a className="message" onClick={()=>{sessionStorage.setItem('pid',item.id);window.location.href='/property-details'}}><i className="fa fa-envelope" /></a></li>
                                                    <li style={{cursor: 'pointer'}} className="readeal-top"><div className="btn btn-yellow">View Details</div></li>
                                                </ul>
                                            </div>
                                       </Link>
                                        </div>
                                    </div>
                                ) }
                            </div>
                        </div>
                    </div>
                    :
                    ""
            }
        </>
    )
}


// class RecentProperties extends Component {
//
//
//     render() {
//
//         let publicUrl = process.env.PUBLIC_URL+'/'
//         let imagealt = 'image'
//         let data = sectiondata.recentproperties
//
//     return <div className="properties-area pd-top-92">
//         <div className="container">
//           <div className="section-title">
//             <h2 className="title">{ data.title }</h2>
//             <Link className="btn-view-all" to={ data.btnurl }>View All</Link>
//           </div>
//           <div className="row">
//             { data.items.map( ( item, i )=>
//                 <div key={ i } className="col-lg-3 col-sm-6">
//                   <div className="single-feature">
//                     <div className="thumb">
//                       <img src={ publicUrl+item.image } alt="img" />
//                     </div>
//                     <div className="details">
//                       <a href="#" className="feature-logo">
//                         <img src={ publicUrl+item.icon } alt={ imagealt } />
//                       </a>
//                       <p className="author"><i className="fa fa-user" /> { item.authorname }</p>
//                       <h6 className="title  readeal-top"><Link to={ item.url }>{ item.title }</Link></h6>
//                       <h6 className="price">{ item.newerprice }</h6><del>{ item.olderprice }</del>
//                       <ul className="info-list">
//                         { item.features.map( ( features, i )=>
//                           <li key={ i } ><i className={ features.icon } /> { features.title }</li>
//                          ) }
//                         <li><img src={publicUrl+"/assets/img/icons/7.png" } alt={ imagealt } /> 1898 sq.</li>
//                       </ul>
//                       <ul className="contact-list">
//                         <li><a className="phone" href="#"><i className="fa fa-phone" /></a></li>
//                         <li><a className="message" href="#"><img src={ publicUrl+"/assets/img/icons/8.png" } alt="img" /></a></li>
//                         <li className="readeal-top"><Link className="btn btn-yellow" to={ item.url }>View Details</Link></li>
//                       </ul>
//                     </div>
//                   </div>
//                 </div>
//              ) }
//           </div>
//         </div>
//       </div>
//
//
//         }
// }

export default RecentProperties