import React, { useEffect, useMemo, useState } from "react";
import SearchAddImage from "../../assets/serachpropAds.gif";
import Api, { test_url } from "../../apis/apis";

const SearchAdd = (props) => {
  const [ad, setAd] = useState([]);

  const getHeaderAds = async () => {
    let res = await Api.getHeaderAdsAction({ type: props.type });
    if (res.status == "200") {
      setAd(res.data);
    }
  };
  useEffect(() => {
    getHeaderAds();
  }, []);
  const imageUrl = useMemo(() => {
    if (ad?.length > 0) {
      return `${test_url}${ad[0]?.imageUrl}`;
    }
    return SearchAddImage;
  }, [ad]);
  return (
    <div className="call-to-action-area pd-top-120">
      {ad?.length > 0 && (
        <a
          href={ad?.length > 0 ? ad[0]?.url : "#"}
          target="_blank"
          rel="noreferrer"
        >
          <img
            style={{ width: "100%", height: "180px", objectFit: "cover" }}
            src={imageUrl}
            alt=""
          />
        </a>
      )}
    </div>
  );
};

export default SearchAdd;
