import React, {useEffect,useState} from 'react';
import {GoogleLogin,GoogleLogout } from "react-google-login";
import {gapi} from "gapi-script";
import Api from "../../apis/apis";
import {useHistory} from 'react-router-dom';


function Registration() {

  let history=useHistory();


  async function handleSuccess(res) {
    console.log(res);

    let data={
      name: res.profileObj.name,
      email: res.profileObj.email,
      profileUrl: res.profileObj.imageUrl
    }

    let result=await Api.userRegisterAndLogin(data);

    if (result.status == '200')
    {
      sessionStorage.setItem('auth',JSON.stringify(result.data));
      alert(result.message);
      history.push('/')
    }
  }

  async function handleLogoutSuccess() {

    sessionStorage.removeItem('auth');
    alert('Logout Successfully');
    history.push('/')

  }


  async function handleFailure(res) {
    console.log(res)
    alert("Something went wrong. Please try Again")
  }
  async function handleLogoutFailure(res) {
    console.log(res)
    alert("Something went wrong. Please try Again")
  }


  return(
      <>
        <div className="register-page-area pd-bottom-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-4 col-lg-5 col-md-6 mb-5 mb-md-0">
                <form className="contact-form-wrap contact-form-bg">
                  <h4 className={'text-center'}>Register/Login</h4>
                  {
                    (sessionStorage.getItem('auth'))?
                        <p className={'text-center'}>
                          <GoogleLogout
                              clientId="15837245467-q3c0g0l0d8gtth3nc1opqi96fedqgqum.apps.googleusercontent.com"
                              buttonText="Logout with Google"
                              onLogoutSuccess={handleLogoutSuccess}
                          />
                        </p>
                        :
                        <p className={'text-center'}>
                          <GoogleLogin
                              clientId="15837245467-q3c0g0l0d8gtth3nc1opqi96fedqgqum.apps.googleusercontent.com"
                              buttonText="Register/Login with Google"
                              onSuccess={handleSuccess}
                              onFailure={handleFailure}


                          />
                        </p>
                  }
                  {/*<div className="rld-single-input">*/}
                  {/*  <input type="text" placeholder="Entry Login" />*/}
                  {/*</div>*/}
                  {/*<div className="rld-single-input">*/}
                  {/*  <input type="password" placeholder="Entry Password" />*/}
                  {/*</div>*/}
                  {/*<div className="btn-wrap">*/}
                  {/*  <button className="btn btn-yellow">Sign In</button>*/}
                  {/*</div>*/}
                </form>
              </div>
              {/*<div className="col-xl-4 col-lg-5 col-md-6">*/}
              {/*  <form className="contact-form-wrap contact-form-bg">*/}
              {/*    <h4>Registration</h4>*/}
              {/*    <div className="rld-single-input">*/}
              {/*      <input type="text" placeholder="First Name" />*/}
              {/*    </div>*/}
              {/*    <div className="rld-single-input">*/}
              {/*      <input type="text" placeholder="Last Name" />*/}
              {/*    </div>*/}
              {/*    <div className="rld-single-input">*/}
              {/*      <input type="text" placeholder="Select User role" />*/}
              {/*    </div>*/}
              {/*    <div className="rld-single-input">*/}
              {/*      <input type="text" placeholder="Password" />*/}
              {/*    </div>*/}
              {/*    <div className="rld-single-input">*/}
              {/*      <input type="text" placeholder="Re-enter password" />*/}
              {/*    </div>*/}
              {/*    <div className="btn-wrap">*/}
              {/*      <button className="btn btn-yellow">Register</button>*/}
              {/*    </div>*/}
              {/*    <ul className="social-icon">*/}
              {/*      <li className="ml-0">*/}
              {/*        <a href="#" target="_blank"><i className="fa fa-facebook  " /></a>*/}
              {/*      </li>*/}
              {/*      <li>*/}
              {/*        <a href="#" target="_blank"><i className="fa fa-google-plus  " /></a>*/}
              {/*      </li>*/}
              {/*      <li>*/}
              {/*        <a href="#" target="_blank"><i className="fa fa-instagram" /></a>*/}
              {/*      </li>*/}
              {/*    </ul>*/}
              {/*  </form>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </>
  )

}

export default Registration